// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Bank$OrcaPaymentPage from "../Bank.bs.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as Recoil$OrcaPaymentPage from "../libraries/Recoil.bs.js";
import * as Country$OrcaPaymentPage from "../Country.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";
import * as ConfirmType$OrcaPaymentPage from "../Types/ConfirmType.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./RecoilAtoms.bs.js";
import * as PaymentUtils$OrcaPaymentPage from "./PaymentUtils.bs.js";
import * as PaymentMethodsRecord$OrcaPaymentPage from "../Payments/PaymentMethodsRecord.bs.js";

var dynamicFieldsEnabledPaymentMethods = [
  "crypto_currency",
  "debit",
  "credit",
  "blik",
  "google_pay",
  "apple_pay",
  "bancontact_card",
  "open_banking_uk",
  "eps",
  "ideal",
  "sofort",
  "pix_transfer",
  "giropay",
  "local_bank_transfer_transfer",
  "afterpay_clearpay",
  "mifinity",
  "upi_collect",
  "sepa",
  "sepa_bank_transfer",
  "instant_bank_transfer",
  "affirm",
  "walley",
  "ach",
  "bacs",
  "pay_bright",
  "multibanco_transfer",
  "paypal"
];

function getName(item, field) {
  var fieldNameArr = field.value.split(" ");
  var requiredFieldsArr = item.required_field.split(".");
  var match = Core__Option.getOr(requiredFieldsArr[requiredFieldsArr.length - 1 | 0], "");
  switch (match) {
    case "first_name" :
        return Core__Option.getOr(fieldNameArr[0], field.value);
    case "last_name" :
        return Core__Array.reduce(fieldNameArr.slice(1), "", (function (acc, item) {
                      if (acc === "") {
                        return item;
                      } else {
                        return acc + " " + item;
                      }
                    }));
    default:
      return field.value;
  }
}

var countryNames = Utils$OrcaPaymentPage.getCountryNames(Country$OrcaPaymentPage.country);

var billingAddressFields = [
  "BillingName",
  "AddressLine1",
  "AddressLine2",
  "AddressCity",
  "AddressState",
  {
    TAG: "AddressCountry",
    _0: countryNames
  },
  "AddressPincode"
];

function isBillingAddressFieldType(fieldType) {
  if (typeof fieldType === "object") {
    if (fieldType.TAG === "AddressCountry") {
      return true;
    } else {
      return false;
    }
  }
  switch (fieldType) {
    case "BillingName" :
    case "AddressLine1" :
    case "AddressLine2" :
    case "AddressCity" :
    case "AddressPincode" :
    case "AddressState" :
        return true;
    default:
      return false;
  }
}

function getBillingAddressPathFromFieldType(fieldType) {
  if (typeof fieldType === "object") {
    if (fieldType.TAG === "AddressCountry") {
      return "payment_method_data.billing.address.country";
    } else {
      return "";
    }
  }
  switch (fieldType) {
    case "AddressLine1" :
        return "payment_method_data.billing.address.line1";
    case "AddressLine2" :
        return "payment_method_data.billing.address.line2";
    case "AddressCity" :
        return "payment_method_data.billing.address.city";
    case "AddressPincode" :
        return "payment_method_data.billing.address.zip";
    case "AddressState" :
        return "payment_method_data.billing.address.state";
    default:
      return "";
  }
}

function removeBillingDetailsIfUseBillingAddress(requiredFields, billingAddress) {
  if (billingAddress.isUseBillingAddress) {
    return requiredFields.filter(function (requiredField) {
                return !isBillingAddressFieldType(requiredField.field_type);
              });
  } else {
    return requiredFields;
  }
}

function addBillingAddressIfUseBillingAddress(fieldsArr, billingAddress) {
  if (billingAddress.isUseBillingAddress) {
    return fieldsArr.concat(billingAddressFields);
  } else {
    return fieldsArr;
  }
}

var clickToPayFields = [
  "Email",
  "PhoneNumber"
];

function isClickToPayFieldType(fieldType) {
  if (typeof fieldType === "object") {
    return false;
  }
  switch (fieldType) {
    case "Email" :
    case "PhoneNumber" :
        return true;
    default:
      return false;
  }
}

function removeClickToPayFieldsIfSaveDetailsWithClickToPay(requiredFields, isSaveDetailsWithClickToPay) {
  if (isSaveDetailsWithClickToPay) {
    return requiredFields.filter(function (requiredField) {
                return !isClickToPayFieldType(requiredField.field_type);
              });
  } else {
    return requiredFields;
  }
}

function addClickToPayFieldsIfSaveDetailsWithClickToPay(fieldsArr, isSaveDetailsWithClickToPay) {
  if (isSaveDetailsWithClickToPay) {
    return Belt_Array.concatMany([
                fieldsArr,
                clickToPayFields
              ]);
  } else {
    return fieldsArr;
  }
}

function checkIfNameIsValid(requiredFieldsType, paymentMethodFields, field) {
  return Core__Array.reduce(requiredFieldsType.filter(function (required_field) {
                  return required_field.field_type === paymentMethodFields;
                }), true, (function (acc, item) {
                var fieldNameArr = field.value.split(" ");
                var requiredFieldsArr = item.required_field.split(".");
                var match = Core__Option.getOr(requiredFieldsArr[requiredFieldsArr.length - 1 | 0], "");
                var fieldValue;
                switch (match) {
                  case "first_name" :
                      fieldValue = Core__Option.getOr(fieldNameArr[0], "");
                      break;
                  case "last_name" :
                      fieldValue = Core__Option.getOr(fieldNameArr[1], "");
                      break;
                  default:
                    fieldValue = field.value;
                }
                if (acc) {
                  return fieldValue !== "";
                } else {
                  return false;
                }
              }));
}

function useRequiredFieldsEmptyAndValid(requiredFields, fieldsArr, countryNames, bankNames, isCardValid, isExpiryValid, isCVCValid, cardNumber, cardExpiry, cvcNumber, isSavedCardFlow) {
  var email = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userEmailAddress);
  var vpaId = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userVpaId);
  var pixCNPJ = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userPixCNPJ);
  var pixCPF = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userPixCPF);
  var pixKey = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userPixKey);
  var fullName = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userFullName);
  var billingName = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userBillingName);
  var line1 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userAddressline1);
  var line2 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userAddressline2);
  var phone = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userPhoneNumber);
  var state = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userAddressState);
  var city = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userAddressCity);
  var postalCode = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userAddressPincode);
  var blikCode = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userBlikCode);
  var country = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userCountry);
  var selectedBank = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userBank);
  var currency = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userCurrency);
  var match = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.areRequiredFieldsValid);
  var setAreRequiredFieldsValid = match[1];
  var areRequiredFieldsValid = match[0];
  var setAreRequiredFieldsEmpty = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.areRequiredFieldsEmpty);
  var match$1 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var billingAddress = match$1.billingAddress;
  var cryptoCurrencyNetworks = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.cryptoCurrencyNetworks);
  var dateOfBirth = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.dateOfBirth);
  var bankAccountNumber = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userBankAccountNumber);
  var fieldsArrWithBillingAddress = addBillingAddressIfUseBillingAddress(fieldsArr, billingAddress);
  React.useEffect((function () {
          var areRequiredFieldsValid = Core__Array.reduce(fieldsArr, true, (function (acc, paymentMethodFields) {
                  if (!acc) {
                    return false;
                  }
                  if (typeof paymentMethodFields !== "object") {
                    switch (paymentMethodFields) {
                      case "Email" :
                          return Core__Option.getOr(email.isValid, false);
                      case "FullName" :
                          if (checkIfNameIsValid(requiredFields, paymentMethodFields, fullName)) {
                            return Core__Option.getOr(fullName.isValid, false);
                          } else {
                            return false;
                          }
                      case "Country" :
                          if (country !== "") {
                            return true;
                          } else {
                            return countryNames.length === 0;
                          }
                      case "Bank" :
                          if (selectedBank !== "") {
                            return true;
                          } else {
                            return bankNames.length === 0;
                          }
                      case "BillingName" :
                          return checkIfNameIsValid(requiredFields, paymentMethodFields, billingName);
                      case "PhoneNumber" :
                          return phone.value !== "";
                      case "AddressLine1" :
                          return line1.value !== "";
                      case "AddressLine2" :
                          if (billingAddress.isUseBillingAddress) {
                            return true;
                          } else {
                            return line2.value !== "";
                          }
                      case "AddressCity" :
                          return city.value !== "";
                      case "StateAndCity" :
                          if (state.value !== "") {
                            return city.value !== "";
                          } else {
                            return false;
                          }
                      case "AddressPincode" :
                          return postalCode.value !== "";
                      case "AddressState" :
                          return state.value !== "";
                      case "BlikCode" :
                          return blikCode.value !== "";
                      case "CardNumber" :
                          return Core__Option.getOr(isCardValid, false);
                      case "CardExpiryMonth" :
                      case "CardExpiryYear" :
                      case "CardExpiryMonthAndYear" :
                          return Core__Option.getOr(isExpiryValid, false);
                      case "CardCvc" :
                          return Core__Option.getOr(isCVCValid, false);
                      case "CardExpiryAndCvc" :
                          if (Core__Option.getOr(isExpiryValid, false)) {
                            return Core__Option.getOr(isCVCValid, false);
                          } else {
                            return false;
                          }
                      case "CryptoCurrencyNetworks" :
                          return cryptoCurrencyNetworks !== "";
                      case "DateOfBirth" :
                          if (dateOfBirth == null) {
                            return false;
                          } else {
                            return Utils$OrcaPaymentPage.checkIs18OrAbove(dateOfBirth);
                          }
                      case "VpaId" :
                          return Core__Option.getOr(vpaId.isValid, false);
                      case "PixKey" :
                          return Core__Option.getOr(pixKey.isValid, false);
                      case "PixCPF" :
                          return Core__Option.getOr(pixCPF.isValid, false);
                      case "PixCNPJ" :
                          return Core__Option.getOr(pixCNPJ.isValid, false);
                      case "BankAccountNumber" :
                      case "IBAN" :
                          return bankAccountNumber.value !== "";
                      default:
                        return true;
                    }
                  } else {
                    switch (paymentMethodFields.TAG) {
                      case "CountryAndPincode" :
                          if (country !== "" || paymentMethodFields._0.length === 0) {
                            return postalCode.value !== "";
                          } else {
                            return false;
                          }
                      case "AddressCountry" :
                          if (country !== "") {
                            return true;
                          } else {
                            return paymentMethodFields._0.length === 0;
                          }
                      case "Currency" :
                          if (currency !== "") {
                            return true;
                          } else {
                            return paymentMethodFields._0.length === 0;
                          }
                      default:
                        return true;
                    }
                  }
                }));
          setAreRequiredFieldsValid(function (param) {
                if (isSavedCardFlow) {
                  return true;
                } else {
                  return areRequiredFieldsValid;
                }
              });
          var areRequiredFieldsEmpty = Core__Array.reduce(fieldsArrWithBillingAddress, false, (function (acc, paymentMethodFields) {
                  if (acc) {
                    return true;
                  }
                  if (typeof paymentMethodFields !== "object") {
                    switch (paymentMethodFields) {
                      case "Email" :
                          return email.value === "";
                      case "FullName" :
                          return fullName.value === "";
                      case "Country" :
                          if (country === "") {
                            return countryNames.length > 0;
                          } else {
                            return false;
                          }
                      case "Bank" :
                          if (selectedBank === "") {
                            return bankNames.length > 0;
                          } else {
                            return false;
                          }
                      case "BillingName" :
                          return billingName.value === "";
                      case "PhoneNumber" :
                          return phone.value === "";
                      case "AddressLine1" :
                          return line1.value === "";
                      case "AddressLine2" :
                          if (billingAddress.isUseBillingAddress) {
                            return false;
                          } else {
                            return line2.value === "";
                          }
                      case "AddressCity" :
                          return city.value === "";
                      case "StateAndCity" :
                          if (city.value === "") {
                            return true;
                          } else {
                            return state.value === "";
                          }
                      case "AddressPincode" :
                          return postalCode.value === "";
                      case "AddressState" :
                          return state.value === "";
                      case "BlikCode" :
                          return blikCode.value === "";
                      case "CardNumber" :
                          return cardNumber === "";
                      case "CardExpiryMonth" :
                          var match = CardUtils$OrcaPaymentPage.getExpiryDates(cardExpiry);
                          return match[0] === "";
                      case "CardExpiryYear" :
                          var match$1 = CardUtils$OrcaPaymentPage.getExpiryDates(cardExpiry);
                          return match$1[1] === "";
                      case "CardExpiryMonthAndYear" :
                          var match$2 = CardUtils$OrcaPaymentPage.getExpiryDates(cardExpiry);
                          if (match$2[0] === "") {
                            return true;
                          } else {
                            return match$2[1] === "";
                          }
                      case "CardCvc" :
                          return cvcNumber === "";
                      case "CardExpiryAndCvc" :
                          var match$3 = CardUtils$OrcaPaymentPage.getExpiryDates(cardExpiry);
                          if (match$3[0] === "" || match$3[1] === "") {
                            return true;
                          } else {
                            return cvcNumber === "";
                          }
                      case "CryptoCurrencyNetworks" :
                          return cryptoCurrencyNetworks === "";
                      case "DateOfBirth" :
                          return dateOfBirth == null;
                      case "PixKey" :
                          return pixKey.value === "";
                      case "PixCPF" :
                          return pixCPF.value === "";
                      case "PixCNPJ" :
                          return pixCNPJ.value === "";
                      case "BankAccountNumber" :
                      case "IBAN" :
                          return bankAccountNumber.value === "";
                      default:
                        return false;
                    }
                  } else {
                    switch (paymentMethodFields.TAG) {
                      case "CountryAndPincode" :
                          if (country === "" && paymentMethodFields._0.length > 0) {
                            return true;
                          } else {
                            return postalCode.value === "";
                          }
                      case "AddressCountry" :
                          if (country === "") {
                            return paymentMethodFields._0.length > 0;
                          } else {
                            return false;
                          }
                      case "Currency" :
                          if (currency === "") {
                            return paymentMethodFields._0.length > 0;
                          } else {
                            return false;
                          }
                      default:
                        return false;
                    }
                  }
                }));
          setAreRequiredFieldsEmpty(function (param) {
                return areRequiredFieldsEmpty;
              });
        }), [
        fieldsArr,
        currency,
        fullName.value,
        country,
        billingName.value,
        line1.value,
        dateOfBirth,
        email,
        vpaId,
        line2.value,
        selectedBank,
        phone.value,
        city.value,
        postalCode,
        state.value,
        blikCode.value,
        pixCNPJ.value,
        pixKey.value,
        pixCPF.value,
        isCardValid,
        isExpiryValid,
        isCVCValid,
        cardNumber,
        cardExpiry,
        cvcNumber,
        bankAccountNumber,
        cryptoCurrencyNetworks
      ]);
  React.useEffect((function () {
          if (isCardValid !== undefined && isExpiryValid !== undefined && isCVCValid !== undefined) {
            CardUtils$OrcaPaymentPage.emitIsFormReadyForSubmission(isCardValid && isExpiryValid && isCVCValid && areRequiredFieldsValid);
          }
          
        }), [
        isCardValid,
        isExpiryValid,
        isCVCValid,
        areRequiredFieldsValid
      ]);
}

function useSetInitialRequiredFields(requiredFields, paymentMethodType) {
  var logger = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.loggerAtom);
  var match = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userEmailAddress, "email", logger);
  var setEmail = match[1];
  var email = match[0];
  var match$1 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userFullName, "fullName", logger);
  var setFullName = match$1[1];
  var fullName = match$1[0];
  var match$2 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userBillingName, "billingName", logger);
  var setBillingName = match$2[1];
  var billingName = match$2[0];
  var match$3 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressline1, "line1", logger);
  var setLine1 = match$3[1];
  var line1 = match$3[0];
  var match$4 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressline2, "line2", logger);
  var setLine2 = match$4[1];
  var line2 = match$4[0];
  var match$5 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userPhoneNumber, "phone", logger);
  var setPhone = match$5[1];
  var phone = match$5[0];
  var match$6 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressState, "state", logger);
  var setState = match$6[1];
  var state = match$6[0];
  var match$7 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressCity, "city", logger);
  var setCity = match$7[1];
  var city = match$7[0];
  var match$8 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressPincode, "postal_code", logger);
  var setPostalCode = match$8[1];
  var postalCode = match$8[0];
  var match$9 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userBlikCode, "blikCode", logger);
  var setBlikCode = match$9[1];
  var blikCode = match$9[0];
  var match$10 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userPixCNPJ, "pixCNPJ", logger);
  var setPixCNPJ = match$10[1];
  var pixCNPJ = match$10[0];
  var match$11 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userPixCPF, "pixCPF", logger);
  var setPixCPF = match$11[1];
  var pixCPF = match$11[0];
  var match$12 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userPixKey, "pixKey", logger);
  var setPixKey = match$12[1];
  var pixKey = match$12[0];
  var match$13 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userCountry, "country", logger);
  var setCountry = match$13[1];
  var country = match$13[0];
  var match$14 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userBank, "selectedBank", logger);
  var setSelectedBank = match$14[1];
  var selectedBank = match$14[0];
  var match$15 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userCurrency, "currency", logger);
  var setCurrency = match$15[1];
  var currency = match$15[0];
  var match$16 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.cryptoCurrencyNetworks);
  var setCryptoCurrencyNetworks = match$16[1];
  var cryptoCurrencyNetworks = match$16[0];
  var match$17 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.dateOfBirth, "dateOfBirth", logger);
  var setDateOfBirth = match$17[1];
  var dateOfBirth = match$17[0];
  var match$18 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userBankAccountNumber, "bankAccountNumber", logger);
  var setBankAccountNumber = match$18[1];
  var bankAccountNumber = match$18[0];
  React.useEffect((function () {
          var getNameValue = function (item) {
            return Core__Array.reduce(requiredFields.filter(function (requiredFields) {
                              return requiredFields.field_type === item.field_type;
                            }), "", (function (acc, item) {
                            var requiredFieldsArr = item.required_field.split(".");
                            var match = Core__Option.getOr(requiredFieldsArr[requiredFieldsArr.length - 1 | 0], "");
                            switch (match) {
                              case "first_name" :
                                  return item.value.concat(acc);
                              case "last_name" :
                                  return acc.concat(" ", item.value);
                              default:
                                return acc;
                            }
                          })).trim();
          };
          var setFields = function (setMethod, field, item, isNameField, isCountryCodeAvailable) {
            if (isNameField && field.value === "") {
              if (Core__Option.isSome(isCountryCodeAvailable)) {
                return setMethod(function (prev) {
                            var newrecord = Caml_obj.obj_dup(prev);
                            newrecord.countryCode = getNameValue(item);
                            return newrecord;
                          });
              } else {
                return setMethod(function (prev) {
                            var newrecord = Caml_obj.obj_dup(prev);
                            newrecord.value = getNameValue(item);
                            return newrecord;
                          });
              }
            } else if (field.value === "") {
              if (Core__Option.isSome(isCountryCodeAvailable)) {
                return setMethod(function (prev) {
                            var newrecord = Caml_obj.obj_dup(prev);
                            newrecord.countryCode = item.value;
                            return newrecord;
                          });
              } else {
                return setMethod(function (prev) {
                            var newrecord = Caml_obj.obj_dup(prev);
                            newrecord.value = item.value;
                            return newrecord;
                          });
              }
            } else {
              return ;
            }
          };
          requiredFields.forEach(function (requiredField) {
                var value = requiredField.value;
                var match = requiredField.field_type;
                var exit = 0;
                if (typeof match !== "object") {
                  switch (match) {
                    case "Email" :
                        var emailValue = email.value;
                        setFields(setEmail, email, requiredField, false, undefined);
                        if (emailValue !== "") {
                          return ;
                        }
                        var newEmail = {
                          value: value,
                          errorString: ""
                        };
                        return Utils$OrcaPaymentPage.checkEmailValid(newEmail, setEmail);
                    case "FullName" :
                        return setFields(setFullName, fullName, requiredField, true, undefined);
                    case "Country" :
                        exit = 1;
                        break;
                    case "Bank" :
                        if (value !== "" && selectedBank === "") {
                          return setSelectedBank(function (param) {
                                      return value;
                                    });
                        } else {
                          return ;
                        }
                    case "BillingName" :
                        return setFields(setBillingName, billingName, requiredField, true, undefined);
                    case "PhoneNumber" :
                        return setFields(setPhone, phone, requiredField, false, undefined);
                    case "PhoneCountryCode" :
                        return setFields(setPhone, phone, requiredField, false, true);
                    case "AddressLine1" :
                        return setFields(setLine1, line1, requiredField, false, undefined);
                    case "AddressLine2" :
                        return setFields(setLine2, line2, requiredField, false, undefined);
                    case "AddressCity" :
                        return setFields(setCity, city, requiredField, false, undefined);
                    case "StateAndCity" :
                        setFields(setState, state, requiredField, false, undefined);
                        return setFields(setCity, city, requiredField, false, undefined);
                    case "AddressPincode" :
                        return setFields(setPostalCode, postalCode, requiredField, false, undefined);
                    case "AddressState" :
                        return setFields(setState, state, requiredField, false, undefined);
                    case "BlikCode" :
                        return setFields(setBlikCode, blikCode, requiredField, false, undefined);
                    case "CryptoCurrencyNetworks" :
                        if (value !== "" && cryptoCurrencyNetworks === "") {
                          return setCryptoCurrencyNetworks(function (param) {
                                      return value;
                                    });
                        } else {
                          return ;
                        }
                    case "DateOfBirth" :
                        if (!(dateOfBirth == null) && value !== "" && dateOfBirth.toDateString() === "") {
                          return setDateOfBirth(function (param) {
                                      return dateOfBirth;
                                    });
                        } else {
                          return ;
                        }
                    case "PixKey" :
                        return setFields(setPixKey, pixKey, requiredField, false, undefined);
                    case "PixCPF" :
                        return setFields(setPixCPF, pixCPF, requiredField, false, undefined);
                    case "PixCNPJ" :
                        return setFields(setPixCNPJ, pixCNPJ, requiredField, false, undefined);
                    case "BankAccountNumber" :
                    case "IBAN" :
                        exit = 2;
                        break;
                    default:
                      return ;
                  }
                } else {
                  switch (match.TAG) {
                    case "CountryAndPincode" :
                        setFields(setPostalCode, postalCode, requiredField, false, undefined);
                        if (!(value !== "" && country === "")) {
                          return ;
                        }
                        var countryCode = Core__Option.getOr(Country$OrcaPaymentPage.getCountry(paymentMethodType).filter(function (item) {
                                    return item.isoAlpha2 === value;
                                  })[0], Country$OrcaPaymentPage.defaultTimeZone);
                        return setCountry(function (param) {
                                    return countryCode.countryName;
                                  });
                    case "AddressCountry" :
                        exit = 1;
                        break;
                    case "Currency" :
                        if (value !== "" && currency === "") {
                          return setCurrency(function (param) {
                                      return value;
                                    });
                        } else {
                          return ;
                        }
                    default:
                      return ;
                  }
                }
                switch (exit) {
                  case 1 :
                      if (value === "") {
                        return ;
                      }
                      var defaultCountry = Core__Option.getOr(Country$OrcaPaymentPage.getCountry(paymentMethodType).filter(function (item) {
                                  return item.isoAlpha2 === value;
                                })[0], Country$OrcaPaymentPage.defaultTimeZone);
                      return setCountry(function (param) {
                                  return defaultCountry.countryName;
                                });
                  case 2 :
                      return setFields(setBankAccountNumber, bankAccountNumber, requiredField, false, undefined);
                  
                }
              });
        }), [requiredFields]);
}

function useRequiredFieldsBody(requiredFields, paymentMethodType, cardNumber, cardExpiry, cvcNumber, isSavedCardFlow, isAllStoredCardsHaveName, setRequiredFieldsBody) {
  var configValue = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var email = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userEmailAddress);
  var vpaId = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userVpaId);
  var pixCNPJ = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userPixCNPJ);
  var pixCPF = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userPixCPF);
  var pixKey = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userPixKey);
  var fullName = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userFullName);
  var billingName = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userBillingName);
  var line1 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userAddressline1);
  var line2 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userAddressline2);
  var phone = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userPhoneNumber);
  var state = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userAddressState);
  var city = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userAddressCity);
  var postalCode = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userAddressPincode);
  var blikCode = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userBlikCode);
  var country = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userCountry);
  var selectedBank = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userBank);
  var currency = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userCurrency);
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var billingAddress = match.billingAddress;
  var cryptoCurrencyNetworks = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.cryptoCurrencyNetworks);
  var dateOfBirth = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.dateOfBirth);
  var bankAccountNumber = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.userBankAccountNumber);
  var getFieldValueFromFieldType = function (fieldType) {
    if (typeof fieldType !== "object") {
      switch (fieldType) {
        case "Email" :
            return email.value;
        case "Country" :
            return country;
        case "Bank" :
            return Core__Option.getOr(Bank$OrcaPaymentPage.getBanks(paymentMethodType).find(function (item) {
                            return item.displayName === selectedBank;
                          }), Bank$OrcaPaymentPage.defaultBank).hyperSwitch;
        case "BillingName" :
            return billingName.value;
        case "PhoneNumber" :
            return phone.value;
        case "PhoneCountryCode" :
            return Core__Option.getOr(phone.countryCode, "");
        case "AddressLine1" :
            return line1.value;
        case "AddressLine2" :
            return line2.value;
        case "AddressCity" :
            return city.value;
        case "AddressPincode" :
            return postalCode.value;
        case "AddressState" :
            return state.value;
        case "BlikCode" :
            return Utils$OrcaPaymentPage.removeHyphen(blikCode.value);
        case "CardNumber" :
            return CardUtils$OrcaPaymentPage.clearSpaces(cardNumber);
        case "CardExpiryMonth" :
            return CardUtils$OrcaPaymentPage.getExpiryDates(cardExpiry)[0];
        case "CardExpiryYear" :
            return CardUtils$OrcaPaymentPage.getExpiryDates(cardExpiry)[1];
        case "CardCvc" :
            return cvcNumber;
        case "CryptoCurrencyNetworks" :
            return cryptoCurrencyNetworks;
        case "DateOfBirth" :
            if (dateOfBirth == null) {
              return "";
            } else {
              return dateOfBirth.toISOString().slice(0, 10);
            }
        case "VpaId" :
            return vpaId.value;
        case "PixKey" :
            return pixKey.value;
        case "PixCPF" :
            return pixCPF.value;
        case "PixCNPJ" :
            return pixCNPJ.value;
        case "BankAccountNumber" :
        case "IBAN" :
            return bankAccountNumber.value;
        default:
          return "";
      }
    } else {
      switch (fieldType.TAG) {
        case "AddressCountry" :
            return Core__Option.getOr(Country$OrcaPaymentPage.getCountry(paymentMethodType).filter(function (item) {
                              return item.countryName === country;
                            })[0], Country$OrcaPaymentPage.defaultTimeZone).isoAlpha2;
        case "Currency" :
            return currency;
        case "LanguagePreference" :
            if (fieldType._0.includes(configValue.config.locale.toUpperCase().split("-").join("_"))) {
              return configValue.config.locale;
            } else {
              return "en";
            }
        default:
          return "";
      }
    }
  };
  var addBillingDetailsIfUseBillingAddress = function (requiredFieldsBody) {
    if (billingAddress.isUseBillingAddress) {
      return Core__Array.reduce(billingAddressFields, requiredFieldsBody, (function (acc, item) {
                    var value = getFieldValueFromFieldType(item);
                    if (item === "BillingName") {
                      var arr = value.split(" ");
                      acc["payment_method_data.billing.address.first_name"] = Core__Option.getOr(arr[0], "");
                      acc["payment_method_data.billing.address.last_name"] = Core__Option.getOr(arr[1], "");
                    } else {
                      var path = getBillingAddressPathFromFieldType(item);
                      acc[path] = value;
                    }
                    return acc;
                  }));
    } else {
      return requiredFieldsBody;
    }
  };
  React.useEffect((function () {
          var requiredFieldsBody = addBillingDetailsIfUseBillingAddress(Core__Array.reduce(requiredFields.filter(function (item) {
                        return item.field_type !== "None";
                      }), {}, (function (acc, item) {
                      var match = item.field_type;
                      var value;
                      if (typeof match !== "object") {
                        switch (match) {
                          case "FullName" :
                              value = getName(item, fullName);
                              break;
                          case "BillingName" :
                              value = getName(item, billingName);
                              break;
                          default:
                            value = getFieldValueFromFieldType(item.field_type);
                        }
                      } else {
                        value = getFieldValueFromFieldType(item.field_type);
                      }
                      if (value !== "") {
                        if (isSavedCardFlow && (item.field_type === "BillingName" || item.field_type === "FullName") && item.display_name === "card_holder_name" && item.required_field === "payment_method_data.card.card_holder_name") {
                          if (!isAllStoredCardsHaveName) {
                            acc["payment_method_data.card_token.card_holder_name"] = value;
                          }
                          
                        } else {
                          acc[item.required_field] = value;
                        }
                      }
                      return acc;
                    })));
          setRequiredFieldsBody(function (param) {
                return requiredFieldsBody;
              });
        }), [
        fullName.value,
        email.value,
        vpaId.value,
        line1.value,
        line2.value,
        pixCNPJ.value,
        pixCPF.value,
        pixKey.value,
        city.value,
        postalCode.value,
        state.value,
        blikCode.value,
        phone.value,
        phone.countryCode,
        currency,
        billingName.value,
        country,
        cardNumber,
        cardExpiry,
        cvcNumber,
        selectedBank,
        cryptoCurrencyNetworks,
        dateOfBirth,
        bankAccountNumber
      ]);
}

function isFieldTypeToRenderOutsideBilling(fieldType) {
  if (typeof fieldType === "object") {
    if (fieldType.TAG === "Currency") {
      return true;
    } else {
      return false;
    }
  }
  switch (fieldType) {
    case "FullName" :
    case "CardNumber" :
    case "CardExpiryMonth" :
    case "CardExpiryYear" :
    case "CardExpiryMonthAndYear" :
    case "CardCvc" :
    case "CardExpiryAndCvc" :
    case "CryptoCurrencyNetworks" :
    case "DateOfBirth" :
    case "VpaId" :
    case "PixKey" :
    case "PixCPF" :
    case "PixCNPJ" :
    case "BankAccountNumber" :
    case "IBAN" :
        return true;
    default:
      return false;
  }
}

function combineStateAndCity(arr) {
  var hasStateAndCity = arr.includes("AddressState") && arr.includes("AddressCity");
  if (hasStateAndCity) {
    arr.push("StateAndCity");
    return arr.filter(function (item) {
                if (typeof item === "object") {
                  return true;
                }
                switch (item) {
                  case "AddressCity" :
                  case "AddressState" :
                      return false;
                  default:
                    return true;
                }
              });
  } else {
    return arr;
  }
}

function combineCountryAndPostal(arr) {
  var hasCountryAndPostal = arr.filter(function (item) {
        if (typeof item !== "object") {
          if (item === "AddressPincode") {
            return true;
          } else {
            return false;
          }
        } else if (item.TAG === "AddressCountry") {
          return true;
        } else {
          return false;
        }
      }).length === 2;
  var options = Core__Array.reduce(arr, [], (function (acc, item) {
          var tmp;
          tmp = typeof item !== "object" ? [] : (
              item.TAG === "AddressCountry" ? item._0 : []
            );
          return acc.concat(tmp);
        }));
  if (hasCountryAndPostal) {
    arr.push({
          TAG: "CountryAndPincode",
          _0: options
        });
    return arr.filter(function (item) {
                if (typeof item !== "object") {
                  if (item === "AddressPincode") {
                    return false;
                  } else {
                    return true;
                  }
                } else if (item.TAG === "AddressCountry") {
                  return false;
                } else {
                  return true;
                }
              });
  } else {
    return arr;
  }
}

function combineCardExpiryMonthAndYear(arr) {
  var hasCardExpiryMonthAndYear = arr.includes("CardExpiryMonth") && arr.includes("CardExpiryYear");
  if (hasCardExpiryMonthAndYear) {
    arr.push("CardExpiryMonthAndYear");
    return arr.filter(function (item) {
                if (typeof item === "object") {
                  return true;
                }
                switch (item) {
                  case "CardExpiryMonth" :
                  case "CardExpiryYear" :
                      return false;
                  default:
                    return true;
                }
              });
  } else {
    return arr;
  }
}

function combineCardExpiryAndCvc(arr) {
  var hasCardExpiryAndCvc = arr.includes("CardExpiryMonthAndYear") && arr.includes("CardCvc");
  if (hasCardExpiryAndCvc) {
    arr.push("CardExpiryAndCvc");
    return arr.filter(function (item) {
                if (typeof item === "object") {
                  return true;
                }
                switch (item) {
                  case "CardExpiryMonthAndYear" :
                  case "CardCvc" :
                      return false;
                  default:
                    return true;
                }
              });
  } else {
    return arr;
  }
}

function updateDynamicFields(arr, billingAddress, isSaveDetailsWithClickToPay) {
  return combineCardExpiryAndCvc(combineCardExpiryMonthAndYear(combineCountryAndPostal(combineStateAndCity(addClickToPayFieldsIfSaveDetailsWithClickToPay(addBillingAddressIfUseBillingAddress(Utils$OrcaPaymentPage.removeDuplicate(arr).filter(function (item) {
                                      return item !== "None";
                                    }), billingAddress), isSaveDetailsWithClickToPay)))));
}

function useSubmitCallback() {
  var logger = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.loggerAtom);
  var match = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressline1, "line1", logger);
  var setLine1 = match[1];
  var line1 = match[0];
  var match$1 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressline2, "line2", logger);
  var setLine2 = match$1[1];
  var line2 = match$1[0];
  var match$2 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressState, "state", logger);
  var setState = match$2[1];
  var state = match$2[0];
  var match$3 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressPincode, "postal_code", logger);
  var setPostalCode = match$3[1];
  var postalCode = match$3[0];
  var match$4 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressCity, "city", logger);
  var setCity = match$4[1];
  var city = match$4[0];
  var match$5 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var billingAddress = match$5.billingAddress;
  var match$6 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var localeString = match$6.localeString;
  return React.useCallback((function (ev) {
                var json = Utils$OrcaPaymentPage.safeParse(ev.data);
                var confirm = ConfirmType$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(json));
                if (confirm.doSubmit) {
                  if (line1.value === "") {
                    setLine1(function (prev) {
                          var newrecord = Caml_obj.obj_dup(prev);
                          newrecord.errorString = localeString.line1EmptyText;
                          return newrecord;
                        });
                  }
                  if (line2.value === "") {
                    setLine2(function (prev) {
                          var newrecord = Caml_obj.obj_dup(prev);
                          newrecord.errorString = billingAddress.isUseBillingAddress ? "" : localeString.line2EmptyText;
                          return newrecord;
                        });
                  }
                  if (state.value === "") {
                    setState(function (prev) {
                          var newrecord = Caml_obj.obj_dup(prev);
                          newrecord.errorString = localeString.stateEmptyText;
                          return newrecord;
                        });
                  }
                  if (postalCode.value === "") {
                    setPostalCode(function (prev) {
                          var newrecord = Caml_obj.obj_dup(prev);
                          newrecord.errorString = localeString.postalCodeEmptyText;
                          return newrecord;
                        });
                  }
                  if (city.value === "") {
                    return setCity(function (prev) {
                                var newrecord = Caml_obj.obj_dup(prev);
                                newrecord.errorString = localeString.cityEmptyText;
                                return newrecord;
                              });
                  } else {
                    return ;
                  }
                }
                
              }), [
              line1,
              line2,
              state,
              city,
              postalCode
            ]);
}

function usePaymentMethodTypeFromList(paymentMethodListValue, paymentMethod, paymentMethodType) {
  return React.useMemo((function () {
                return Core__Option.getOr(PaymentMethodsRecord$OrcaPaymentPage.getPaymentMethodTypeFromList(paymentMethodListValue, paymentMethod, PaymentUtils$OrcaPaymentPage.getPaymentMethodName(paymentMethod, paymentMethodType)), PaymentMethodsRecord$OrcaPaymentPage.defaultPaymentMethodType);
              }), [
              paymentMethodListValue,
              paymentMethod,
              paymentMethodType
            ]);
}

function removeRequiredFieldsDuplicates(requiredFields) {
  return Core__Array.reduce(requiredFields, [
                [],
                []
              ], (function (param, item) {
                  var uniqueRequiredFields = param[1];
                  var requiredFieldKeys = param[0];
                  var requiredField = item.required_field;
                  if (!requiredFieldKeys.includes(requiredField)) {
                    requiredFieldKeys.push(requiredField);
                    uniqueRequiredFields.push(item);
                  }
                  return [
                          requiredFieldKeys,
                          uniqueRequiredFields
                        ];
                }))[1];
}

function getNameFromString(name, requiredFieldsArr) {
  var nameArr = name.split(" ");
  var nameArrLength = nameArr.length;
  var match = Core__Option.getOr(requiredFieldsArr[requiredFieldsArr.length - 1 | 0], "");
  var tmp;
  switch (match) {
    case "first_name" :
        var end = nameArrLength === 1 ? nameArrLength : nameArrLength - 1 | 0;
        tmp = Core__Array.reduce(nameArr.slice(0, end), "", (function (acc, item) {
                return acc + " " + item;
              }));
        break;
    case "last_name" :
        tmp = nameArrLength === 1 ? "" : Core__Option.getOr(nameArr[nameArrLength - 1 | 0], name);
        break;
    default:
      tmp = name;
  }
  return tmp.trim();
}

function getNameFromFirstAndLastName(firstName, lastName, requiredFieldsArr) {
  var match = Core__Option.getOr(requiredFieldsArr[requiredFieldsArr.length - 1 | 0], "");
  var tmp;
  switch (match) {
    case "first_name" :
        tmp = firstName;
        break;
    case "last_name" :
        tmp = lastName;
        break;
    default:
      tmp = firstName.concat(" ", lastName);
  }
  return tmp.trim();
}

var defaultRequiredFieldsArray = [
  {
    required_field: "email",
    display_name: "email",
    field_type: "Email",
    value: ""
  },
  {
    required_field: "payment_method_data.billing.address.state",
    display_name: "state",
    field_type: "AddressState",
    value: ""
  },
  {
    required_field: "payment_method_data.billing.address.first_name",
    display_name: "billing_first_name",
    field_type: "BillingName",
    value: ""
  },
  {
    required_field: "payment_method_data.billing.address.city",
    display_name: "city",
    field_type: "AddressCity",
    value: ""
  },
  {
    required_field: "payment_method_data.billing.address.country",
    display_name: "country",
    field_type: {
      TAG: "AddressCountry",
      _0: ["ALL"]
    },
    value: ""
  },
  {
    required_field: "payment_method_data.billing.address.line1",
    display_name: "line",
    field_type: "AddressLine1",
    value: ""
  },
  {
    required_field: "payment_method_data.billing.address.zip",
    display_name: "zip",
    field_type: "AddressPincode",
    value: ""
  },
  {
    required_field: "payment_method_data.billing.address.last_name",
    display_name: "billing_last_name",
    field_type: "BillingName",
    value: ""
  }
];

function getApplePayRequiredFields(billingContact, shippingContact, requiredFieldsOpt, statesList) {
  var requiredFields = requiredFieldsOpt !== undefined ? requiredFieldsOpt : defaultRequiredFieldsArray;
  return Core__Array.reduce(requiredFields, {}, (function (acc, item) {
                var requiredFieldsArr = item.required_field.split(".");
                var getName = function (firstName, lastName) {
                  var match = Core__Option.getOr(requiredFieldsArr[requiredFieldsArr.length - 1 | 0], "");
                  var tmp;
                  switch (match) {
                    case "first_name" :
                        tmp = firstName;
                        break;
                    case "last_name" :
                        tmp = lastName;
                        break;
                    default:
                      tmp = firstName.concat(" ", lastName);
                  }
                  return tmp.trim();
                };
                var getAddressLine = function (addressLines, index) {
                  return Core__Option.getOr(addressLines[index], "");
                };
                var billingCountryCode = billingContact.countryCode.toUpperCase();
                var shippingCountryCode = shippingContact.countryCode.toUpperCase();
                var match = item.field_type;
                var fieldVal;
                var exit = 0;
                if (typeof match !== "object") {
                  switch (match) {
                    case "Email" :
                        fieldVal = shippingContact.emailAddress;
                        break;
                    case "Country" :
                        fieldVal = billingCountryCode;
                        break;
                    case "FullName" :
                    case "BillingName" :
                        exit = 1;
                        break;
                    case "PhoneNumber" :
                        fieldVal = shippingContact.phoneNumber;
                        break;
                    case "AddressLine1" :
                        fieldVal = getAddressLine(billingContact.addressLines, 0);
                        break;
                    case "AddressLine2" :
                        fieldVal = getAddressLine(billingContact.addressLines, 1);
                        break;
                    case "AddressCity" :
                        fieldVal = billingContact.locality;
                        break;
                    case "AddressPincode" :
                        fieldVal = billingContact.postalCode;
                        break;
                    case "AddressState" :
                        fieldVal = Utils$OrcaPaymentPage.getStateNameFromStateCodeAndCountry(statesList, billingContact.administrativeArea, billingCountryCode);
                        break;
                    case "ShippingName" :
                        fieldVal = getName(shippingContact.givenName, shippingContact.familyName);
                        break;
                    case "ShippingAddressLine1" :
                        fieldVal = getAddressLine(shippingContact.addressLines, 0);
                        break;
                    case "ShippingAddressLine2" :
                        fieldVal = getAddressLine(shippingContact.addressLines, 1);
                        break;
                    case "ShippingAddressCity" :
                        fieldVal = shippingContact.locality;
                        break;
                    case "ShippingAddressPincode" :
                        fieldVal = shippingContact.postalCode;
                        break;
                    case "ShippingAddressState" :
                        fieldVal = Utils$OrcaPaymentPage.getStateNameFromStateCodeAndCountry(statesList, shippingContact.administrativeArea, shippingCountryCode);
                        break;
                    default:
                      fieldVal = "";
                  }
                } else {
                  switch (match.TAG) {
                    case "AddressCountry" :
                        fieldVal = billingCountryCode;
                        break;
                    case "ShippingAddressCountry" :
                        fieldVal = shippingCountryCode;
                        break;
                    default:
                      fieldVal = "";
                  }
                }
                if (exit === 1) {
                  fieldVal = getNameFromFirstAndLastName(billingContact.givenName, billingContact.familyName, requiredFieldsArr);
                }
                if (fieldVal !== "") {
                  acc[item.required_field] = fieldVal;
                }
                return acc;
              }));
}

function getGooglePayRequiredFields(billingContact, shippingContact, requiredFieldsOpt, statesList, email) {
  var requiredFields = requiredFieldsOpt !== undefined ? requiredFieldsOpt : defaultRequiredFieldsArray;
  return Core__Array.reduce(requiredFields, {}, (function (acc, item) {
                var requiredFieldsArr = item.required_field.split(".");
                var match = item.field_type;
                var fieldVal;
                if (typeof match !== "object") {
                  switch (match) {
                    case "Email" :
                        fieldVal = email;
                        break;
                    case "Country" :
                        fieldVal = billingContact.countryCode;
                        break;
                    case "FullName" :
                    case "BillingName" :
                        fieldVal = getNameFromString(billingContact.name, requiredFieldsArr);
                        break;
                    case "PhoneNumber" :
                        fieldVal = shippingContact.phoneNumber.replaceAll(" ", "").replaceAll("-", "");
                        break;
                    case "AddressLine1" :
                        fieldVal = billingContact.address1;
                        break;
                    case "AddressLine2" :
                        fieldVal = billingContact.address2;
                        break;
                    case "AddressCity" :
                        fieldVal = billingContact.locality;
                        break;
                    case "AddressPincode" :
                        fieldVal = billingContact.postalCode;
                        break;
                    case "AddressState" :
                        fieldVal = Utils$OrcaPaymentPage.getStateNameFromStateCodeAndCountry(statesList, billingContact.administrativeArea, billingContact.countryCode);
                        break;
                    case "ShippingName" :
                        fieldVal = getNameFromString(shippingContact.name, requiredFieldsArr);
                        break;
                    case "ShippingAddressLine1" :
                        fieldVal = shippingContact.address1;
                        break;
                    case "ShippingAddressLine2" :
                        fieldVal = shippingContact.address2;
                        break;
                    case "ShippingAddressCity" :
                        fieldVal = shippingContact.locality;
                        break;
                    case "ShippingAddressPincode" :
                        fieldVal = shippingContact.postalCode;
                        break;
                    case "ShippingAddressState" :
                        fieldVal = Utils$OrcaPaymentPage.getStateNameFromStateCodeAndCountry(statesList, shippingContact.administrativeArea, shippingContact.countryCode);
                        break;
                    default:
                      fieldVal = "";
                  }
                } else {
                  switch (match.TAG) {
                    case "AddressCountry" :
                        fieldVal = billingContact.countryCode;
                        break;
                    case "ShippingAddressCountry" :
                        fieldVal = shippingContact.countryCode;
                        break;
                    default:
                      fieldVal = "";
                  }
                }
                if (fieldVal !== "") {
                  acc[item.required_field] = fieldVal;
                }
                return acc;
              }));
}

function getPaypalRequiredFields(details, paymentMethodTypes, statesList) {
  return Core__Array.reduce(paymentMethodTypes.required_fields, {}, (function (acc, item) {
                var requiredFieldsArr = item.required_field.split(".");
                var match = item.field_type;
                var fieldVal;
                if (typeof match !== "object") {
                  switch (match) {
                    case "Email" :
                        fieldVal = details.email;
                        break;
                    case "PhoneNumber" :
                        fieldVal = details.phone;
                        break;
                    case "ShippingName" :
                        var name = details.shippingAddress.recipientName;
                        fieldVal = Core__Option.map(name, (function (__x) {
                                return getNameFromString(__x, requiredFieldsArr);
                              }));
                        break;
                    case "ShippingAddressLine1" :
                        fieldVal = details.shippingAddress.line1;
                        break;
                    case "ShippingAddressLine2" :
                        fieldVal = details.shippingAddress.line2;
                        break;
                    case "ShippingAddressCity" :
                        fieldVal = details.shippingAddress.city;
                        break;
                    case "ShippingAddressPincode" :
                        fieldVal = details.shippingAddress.postalCode;
                        break;
                    case "ShippingAddressState" :
                        var administrativeArea = Core__Option.getOr(details.shippingAddress.state, "");
                        var countryCode = Core__Option.getOr(details.shippingAddress.countryCode, "");
                        fieldVal = Utils$OrcaPaymentPage.getStateNameFromStateCodeAndCountry(statesList, administrativeArea, countryCode);
                        break;
                    default:
                      fieldVal = undefined;
                  }
                } else {
                  fieldVal = match.TAG === "ShippingAddressCountry" ? details.shippingAddress.countryCode : undefined;
                }
                Core__Option.mapOr(fieldVal, undefined, (function (fieldVal) {
                        acc[item.required_field] = fieldVal;
                      }));
                return acc;
              }));
}

function getKlarnaRequiredFields(shippingContact, paymentMethodTypes, statesList) {
  return Core__Array.reduce(paymentMethodTypes.required_fields, {}, (function (acc, item) {
                var requiredFieldsArr = item.required_field.split(".");
                var match = item.field_type;
                var fieldVal;
                if (typeof match !== "object") {
                  switch (match) {
                    case "Email" :
                        fieldVal = shippingContact.email;
                        break;
                    case "PhoneNumber" :
                        fieldVal = shippingContact.phone;
                        break;
                    case "ShippingName" :
                        fieldVal = getNameFromFirstAndLastName(shippingContact.given_name, shippingContact.family_name, requiredFieldsArr);
                        break;
                    case "ShippingAddressLine1" :
                        fieldVal = shippingContact.street_address;
                        break;
                    case "ShippingAddressCity" :
                        fieldVal = shippingContact.city;
                        break;
                    case "ShippingAddressPincode" :
                        fieldVal = shippingContact.postal_code;
                        break;
                    case "ShippingAddressState" :
                        var administrativeArea = shippingContact.region;
                        var countryCode = shippingContact.country;
                        fieldVal = Utils$OrcaPaymentPage.getStateNameFromStateCodeAndCountry(statesList, administrativeArea, countryCode);
                        break;
                    default:
                      fieldVal = "";
                  }
                } else {
                  fieldVal = match.TAG === "ShippingAddressCountry" ? shippingContact.country : "";
                }
                if (fieldVal !== "") {
                  acc[item.required_field] = fieldVal;
                }
                return acc;
              }));
}

export {
  dynamicFieldsEnabledPaymentMethods ,
  getName ,
  countryNames ,
  billingAddressFields ,
  isBillingAddressFieldType ,
  getBillingAddressPathFromFieldType ,
  removeBillingDetailsIfUseBillingAddress ,
  addBillingAddressIfUseBillingAddress ,
  clickToPayFields ,
  isClickToPayFieldType ,
  removeClickToPayFieldsIfSaveDetailsWithClickToPay ,
  addClickToPayFieldsIfSaveDetailsWithClickToPay ,
  checkIfNameIsValid ,
  useRequiredFieldsEmptyAndValid ,
  useSetInitialRequiredFields ,
  useRequiredFieldsBody ,
  isFieldTypeToRenderOutsideBilling ,
  combineStateAndCity ,
  combineCountryAndPostal ,
  combineCardExpiryMonthAndYear ,
  combineCardExpiryAndCvc ,
  updateDynamicFields ,
  useSubmitCallback ,
  usePaymentMethodTypeFromList ,
  removeRequiredFieldsDuplicates ,
  getNameFromString ,
  getNameFromFirstAndLastName ,
  defaultRequiredFieldsArray ,
  getApplePayRequiredFields ,
  getGooglePayRequiredFields ,
  getPaypalRequiredFields ,
  getKlarnaRequiredFields ,
}
/* countryNames Not a pure module */
