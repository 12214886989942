// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Recoil from "recoil";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";
import * as ApiEndpoint$OrcaPaymentPage from "./ApiEndpoint.bs.js";
import * as BrowserSpec$OrcaPaymentPage from "../BrowserSpec.bs.js";
import * as HyperLogger$OrcaPaymentPage from "../hyper-log-catcher/HyperLogger.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "./LoggerUtils.bs.js";
import * as PaymentBody$OrcaPaymentPage from "./PaymentBody.bs.js";
import * as PaymentType$OrcaPaymentPage from "../Types/PaymentType.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./RecoilAtoms.bs.js";
import * as PaymentError$OrcaPaymentPage from "../Types/PaymentError.bs.js";
import * as CardThemeType$OrcaPaymentPage from "../Types/CardThemeType.bs.js";
import * as PaymentConfirmTypes$OrcaPaymentPage from "../Types/PaymentConfirmTypes.bs.js";
import * as PaymentMethodsRecord$OrcaPaymentPage from "../Payments/PaymentMethodsRecord.bs.js";

function getPaymentType(paymentMethodType) {
  switch (paymentMethodType) {
    case "apple_pay" :
        return "Applepay";
    case "" :
    case "credit" :
    case "debit" :
        return "Card";
    case "google_pay" :
        return "Gpay";
    case "paze" :
        return "Paze";
    default:
      return "Other";
  }
}

function closePaymentLoaderIfAny() {
  Utils$OrcaPaymentPage.messageParentWindow(undefined, [[
          "fullscreen",
          false
        ]]);
}

function retrievePaymentIntent(clientSecret, headers, optLogger, customPodUri, isForceSyncOpt) {
  var isForceSync = isForceSyncOpt !== undefined ? isForceSyncOpt : false;
  var paymentIntentID = Utils$OrcaPaymentPage.getPaymentId(clientSecret);
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(undefined, undefined);
  var forceSync = isForceSync ? "&force_sync=true" : "";
  var uri = endpoint + "/payments/" + paymentIntentID + "?client_secret=" + clientSecret + forceSync;
  LoggerUtils$OrcaPaymentPage.logApi("RETRIEVE_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, optLogger, "INFO", "API", undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, undefined, ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, customPodUri), "GET").then(function (res) {
                  var statusCode = res.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return res.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("RETRIEVE_CALL", statusCode, data, "Err", uri, undefined, undefined, optLogger, "ERROR", "API", undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("RETRIEVE_CALL", statusCode, undefined, "Response", uri, undefined, undefined, optLogger, "INFO", "API", undefined);
                    return res.json();
                  }
                }), (function (e) {
                console.log("Unable to retrieve payment details because of ", e);
                return Promise.resolve(null);
              }));
}

function threeDsAuth(clientSecret, optLogger, threeDsMethodComp, headers) {
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(undefined, undefined);
  var paymentIntentID = Core__Option.getOr(clientSecret.split("_secret_")[0], "");
  var url = endpoint + "/payments/" + paymentIntentID + "/3ds/authentication";
  var body = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
          [
            "client_secret",
            clientSecret
          ],
          [
            "device_channel",
            "BRW"
          ],
          [
            "threeds_method_comp_ind",
            threeDsMethodComp
          ]
        ].concat(BrowserSpec$OrcaPaymentPage.broswerInfo()));
  LoggerUtils$OrcaPaymentPage.logApi("AUTHENTICATION_CALL_INIT", undefined, undefined, "Request", url, undefined, undefined, optLogger, "INFO", "API", undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(url, JSON.stringify(body), Object.fromEntries(headers), "POST").then(function (res) {
                  var statusCode = res.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return res.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("AUTHENTICATION_CALL", statusCode, data, "Err", url, undefined, undefined, optLogger, "ERROR", "API", undefined);
                                var dict = Utils$OrcaPaymentPage.getDictFromJson(data);
                                var errorObj = PaymentError$OrcaPaymentPage.itemToObjMapper(dict);
                                closePaymentLoaderIfAny();
                                Utils$OrcaPaymentPage.postFailedSubmitResponse(errorObj.error.type_, errorObj.error.message);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("AUTHENTICATION_CALL", statusCode, undefined, "Response", url, undefined, undefined, optLogger, undefined, undefined, undefined);
                    return res.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                console.log("Unable to call 3ds auth ", exceptionMessage);
                LoggerUtils$OrcaPaymentPage.logApi("AUTHENTICATION_CALL", undefined, exceptionMessage, "NoResponse", url, undefined, undefined, optLogger, "ERROR", "API", undefined);
                return Promise.reject(err);
              }));
}

function pollRetrievePaymentIntent(clientSecret, headers, optLogger, customPodUri, isForceSyncOpt) {
  var isForceSync = isForceSyncOpt !== undefined ? isForceSyncOpt : false;
  return Core__Promise.$$catch(retrievePaymentIntent(clientSecret, headers, optLogger, customPodUri, isForceSync).then(function (json) {
                  var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                  var status = Utils$OrcaPaymentPage.getString(dict, "status", "");
                  if (status === "succeeded" || status === "failed") {
                    return Promise.resolve(json);
                  } else {
                    return Utils$OrcaPaymentPage.delay(2000).then(function (_val) {
                                return pollRetrievePaymentIntent(clientSecret, headers, optLogger, customPodUri, isForceSync);
                              });
                  }
                }), (function (e) {
                console.log("Unable to retrieve payment due to following error", e);
                return pollRetrievePaymentIntent(clientSecret, headers, optLogger, customPodUri, isForceSync);
              }));
}

function retrieveStatus(headers, customPodUri, pollID, logger) {
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(undefined, undefined);
  var uri = endpoint + "/poll/status/" + pollID;
  LoggerUtils$OrcaPaymentPage.logApi("POLL_STATUS_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, logger, "INFO", "API", undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, undefined, ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, customPodUri), "GET").then(function (res) {
                  var statusCode = res.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return res.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("POLL_STATUS_CALL", statusCode, data, "Err", uri, undefined, undefined, logger, "ERROR", "API", undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("POLL_STATUS_CALL", statusCode, undefined, "Response", uri, undefined, undefined, logger, "INFO", "API", undefined);
                    return res.json();
                  }
                }), (function (e) {
                console.log("Unable to Poll status details because of ", e);
                return Promise.resolve(null);
              }));
}

function pollStatus(headers, customPodUri, pollId, interval, count, returnUrl, logger) {
  return Core__Promise.$$catch(retrieveStatus(headers, customPodUri, pollId, logger).then(function (json) {
                  var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                  var status = Utils$OrcaPaymentPage.getString(dict, "status", "");
                  return new Promise((function (resolve, param) {
                                if (status === "completed") {
                                  return resolve(json);
                                } else if (count === 0) {
                                  Utils$OrcaPaymentPage.messageParentWindow(undefined, [[
                                          "fullscreen",
                                          false
                                        ]]);
                                  return Utils$OrcaPaymentPage.openUrl(returnUrl);
                                } else {
                                  Utils$OrcaPaymentPage.delay(interval).then(function (param) {
                                        return pollStatus(headers, customPodUri, pollId, interval, count - 1 | 0, returnUrl, logger).then(function (res) {
                                                    resolve(res);
                                                    return Promise.resolve();
                                                  });
                                      });
                                  return ;
                                }
                              }));
                }), (function (e) {
                console.log("Unable to retrieve payment due to following error", e);
                return pollStatus(headers, customPodUri, pollId, interval, count - 1 | 0, returnUrl, logger).then(function (res) {
                            return Promise.resolve(res);
                          });
              }));
}

function intentCall(fetchApi, uri, headers, bodyStr, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, fetchMethod, setIsManualRetryEnabled, customPodUri, sdkHandleOneClickConfirmPayment, counter, isPaymentSessionOpt, isCallbackUsedVal, componentNameOpt, shouldUseTopRedirection) {
  var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
  var componentName = componentNameOpt !== undefined ? componentNameOpt : "payment";
  var isConfirm = uri.includes("/confirm");
  var isCompleteAuthorize = uri.includes("/complete_authorize");
  var isPostSessionTokens = uri.includes("/post_session_tokens");
  var match = isConfirm ? [
      "CONFIRM_CALL",
      "CONFIRM_CALL_INIT"
    ] : (
      isCompleteAuthorize ? [
          "COMPLETE_AUTHORIZE_CALL",
          "COMPLETE_AUTHORIZE_CALL_INIT"
        ] : (
          isPostSessionTokens ? [
              "POST_SESSION_TOKENS_CALL",
              "POST_SESSION_TOKENS_CALL_INIT"
            ] : [
              "RETRIEVE_CALL",
              "RETRIEVE_CALL_INIT"
            ]
        )
    );
  var eventName = match[0];
  LoggerUtils$OrcaPaymentPage.logApi(match[1], undefined, undefined, "Request", uri, undefined, undefined, optLogger, "INFO", "API", isPaymentSession);
  var handleOpenUrl = function (url) {
    if (isPaymentSession) {
      return Window$OrcaPaymentPage.replaceRootHref(url, shouldUseTopRedirection);
    } else {
      return Utils$OrcaPaymentPage.openUrl(url);
    }
  };
  return Core__Promise.$$catch(fetchApi(uri, bodyStr, ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, customPodUri), fetchMethod).then(function (res) {
                  var statusCode = res.status.toString();
                  var url = new URL(confirmParam.return_url);
                  url.searchParams.set("payment_intent_client_secret", clientSecret);
                  url.searchParams.set("status", "failed");
                  Utils$OrcaPaymentPage.messageParentWindow(undefined, [[
                          "confirmParams",
                          confirmParam
                        ]]);
                  if (statusCode.charAt(0) !== "2") {
                    return Core__Promise.$$catch(res.json().then(function (data) {
                                    return new Promise((function (resolve, param) {
                                                    if (isConfirm) {
                                                      var paymentMethod;
                                                      paymentMethod = paymentType === "Card" ? "CARD" : Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(Utils$OrcaPaymentPage.safeParse(bodyStr)), "payment_method_type", "");
                                                      LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, JSON.stringify(data), undefined, "PAYMENT_FAILED", paymentMethod, undefined);
                                                    }
                                                    LoggerUtils$OrcaPaymentPage.logApi(eventName, statusCode, data, "Err", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession);
                                                    var dict = Utils$OrcaPaymentPage.getDictFromJson(data);
                                                    var errorObj = PaymentError$OrcaPaymentPage.itemToObjMapper(dict);
                                                    if (!isPaymentSession) {
                                                      closePaymentLoaderIfAny();
                                                      Utils$OrcaPaymentPage.postFailedSubmitResponse(errorObj.error.type_, errorObj.error.message);
                                                    }
                                                    if (handleUserError) {
                                                      return handleOpenUrl(url.href);
                                                    }
                                                    var failedSubmitResponse = Utils$OrcaPaymentPage.getFailedSubmitResponse(errorObj.error.type_, errorObj.error.message);
                                                    resolve(failedSubmitResponse);
                                                  })).then(function (prim) {
                                                return Promise.resolve(prim);
                                              });
                                  }), (function (err) {
                                  return new Promise((function (resolve, param) {
                                                  var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                                                  LoggerUtils$OrcaPaymentPage.logApi(eventName, statusCode, exceptionMessage, "NoResponse", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession);
                                                  if (counter >= 5) {
                                                    if (!isPaymentSession) {
                                                      closePaymentLoaderIfAny();
                                                      Utils$OrcaPaymentPage.postFailedSubmitResponse("server_error", "Something went wrong");
                                                    }
                                                    if (handleUserError) {
                                                      return handleOpenUrl(url.href);
                                                    }
                                                    var failedSubmitResponse = Utils$OrcaPaymentPage.getFailedSubmitResponse("server_error", "Something went wrong");
                                                    return resolve(failedSubmitResponse);
                                                  }
                                                  var paymentIntentID = Utils$OrcaPaymentPage.getPaymentId(clientSecret);
                                                  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(confirmParam.publishableKey, isConfirm);
                                                  var retrieveUri = endpoint + "/payments/" + paymentIntentID + "?client_secret=" + clientSecret;
                                                  intentCall(fetchApi, retrieveUri, headers, bodyStr, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, "GET", setIsManualRetryEnabled, customPodUri, sdkHandleOneClickConfirmPayment, counter + 1 | 0, undefined, undefined, componentName, shouldUseTopRedirection).then(function (res) {
                                                        resolve(res);
                                                        return Promise.resolve();
                                                      });
                                                })).then(function (prim) {
                                              return Promise.resolve(prim);
                                            });
                                }));
                  } else {
                    return res.json().then(function (data) {
                                return new Promise((function (resolve, param) {
                                                LoggerUtils$OrcaPaymentPage.logApi(eventName, statusCode, undefined, "Response", uri, undefined, undefined, optLogger, undefined, undefined, isPaymentSession);
                                                var intent = PaymentConfirmTypes$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(data));
                                                var paymentMethod;
                                                paymentMethod = paymentType === "Card" ? "CARD" : intent.payment_method_type;
                                                var url = new URL(confirmParam.return_url);
                                                url.searchParams.set("payment_intent_client_secret", clientSecret);
                                                url.searchParams.set("status", intent.status);
                                                var handleProcessingStatus = function (paymentType, sdkHandleOneClickConfirmPayment) {
                                                  var exit = 0;
                                                  switch (paymentType) {
                                                    case "Card" :
                                                        exit = 2;
                                                        break;
                                                    case "Gpay" :
                                                    case "Applepay" :
                                                    case "Paypal" :
                                                        exit = sdkHandleOneClickConfirmPayment ? 1 : 2;
                                                        break;
                                                    default:
                                                      exit = 1;
                                                  }
                                                  switch (exit) {
                                                    case 1 :
                                                        if (Core__Option.getOr(isCallbackUsedVal, false)) {
                                                          closePaymentLoaderIfAny();
                                                          return Utils$OrcaPaymentPage.handleOnCompleteDoThisMessage(undefined);
                                                        } else {
                                                          return handleOpenUrl(url.href);
                                                        }
                                                    case 2 :
                                                        if (isPaymentSession) {
                                                          if (confirmParam.redirect === "always") {
                                                            if (Core__Option.getOr(isCallbackUsedVal, false)) {
                                                              return Utils$OrcaPaymentPage.handleOnCompleteDoThisMessage(undefined);
                                                            } else {
                                                              return handleOpenUrl(url.href);
                                                            }
                                                          } else {
                                                            return resolve(data);
                                                          }
                                                        } else {
                                                          if (Core__Option.getOr(isCallbackUsedVal, false)) {
                                                            Utils$OrcaPaymentPage.handleOnCompleteDoThisMessage(undefined);
                                                          } else {
                                                            closePaymentLoaderIfAny();
                                                          }
                                                          return Utils$OrcaPaymentPage.postSubmitResponse(data, url.href);
                                                        }
                                                    
                                                  }
                                                };
                                                if (intent.status === "requires_customer_action") {
                                                  if (intent.nextAction.type_ === "redirect_to_url") {
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, "", intent.nextAction.redirectToUrl, "REDIRECTING_USER", paymentMethod, undefined);
                                                    return handleOpenUrl(intent.nextAction.redirectToUrl);
                                                  }
                                                  if (intent.nextAction.type_ === "display_bank_transfer_information") {
                                                    var obj = intent.nextAction.bank_transfer_steps_and_charges_details;
                                                    var metadata = obj !== undefined ? Utils$OrcaPaymentPage.getDictFromJson(obj) : ({});
                                                    var dict = Utils$OrcaPaymentPage.deepCopyDict(metadata);
                                                    dict["data"] = data;
                                                    dict["url"] = url.href;
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, "", JSON.stringify(dict), "DISPLAY_BANK_TRANSFER_INFO_PAGE", paymentMethod, undefined);
                                                    if (!isPaymentSession) {
                                                      Utils$OrcaPaymentPage.messageParentWindow(undefined, [
                                                            [
                                                              "fullscreen",
                                                              true
                                                            ],
                                                            [
                                                              "param",
                                                              intent.payment_method_type + "BankTransfer"
                                                            ],
                                                            [
                                                              "iframeId",
                                                              iframeId
                                                            ],
                                                            [
                                                              "metadata",
                                                              dict
                                                            ]
                                                          ]);
                                                    }
                                                    return resolve(data);
                                                  }
                                                  if (intent.nextAction.type_ === "qr_code_information") {
                                                    var qrData = Core__Option.getOr(intent.nextAction.image_data_url, "");
                                                    var expiryTime = Core__Option.getOr(intent.nextAction.display_to_timestamp, 0.0);
                                                    var headerObj = {};
                                                    headers.forEach(function (entries) {
                                                          headerObj[entries[0]] = entries[1];
                                                        });
                                                    var metaData = Object.fromEntries([
                                                          [
                                                            "qrData",
                                                            qrData
                                                          ],
                                                          [
                                                            "paymentIntentId",
                                                            clientSecret
                                                          ],
                                                          [
                                                            "publishableKey",
                                                            confirmParam.publishableKey
                                                          ],
                                                          [
                                                            "headers",
                                                            headerObj
                                                          ],
                                                          [
                                                            "expiryTime",
                                                            expiryTime.toString()
                                                          ],
                                                          [
                                                            "url",
                                                            url.href
                                                          ]
                                                        ]);
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, "", JSON.stringify(metaData), "DISPLAY_QR_CODE_INFO_PAGE", paymentMethod, undefined);
                                                    if (!isPaymentSession) {
                                                      Utils$OrcaPaymentPage.messageParentWindow(undefined, [
                                                            [
                                                              "fullscreen",
                                                              true
                                                            ],
                                                            [
                                                              "param",
                                                              "qrData"
                                                            ],
                                                            [
                                                              "iframeId",
                                                              iframeId
                                                            ],
                                                            [
                                                              "metadata",
                                                              metaData
                                                            ]
                                                          ]);
                                                    }
                                                    return resolve(data);
                                                  }
                                                  if (intent.nextAction.type_ === "three_ds_invoke") {
                                                    var threeDsData = Core__Option.getOr(Core__Option.flatMap(intent.nextAction.three_ds_data, Core__JSON.Decode.object), {});
                                                    var do3dsMethodCall = Utils$OrcaPaymentPage.getBoolValue(Core__JSON.Decode.bool(Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(threeDsData["three_ds_method_details"], Core__JSON.Decode.object), (function (x) {
                                                                        return x["three_ds_method_data_submission"];
                                                                      })), {})));
                                                    var headerObj$1 = {};
                                                    headers.forEach(function (entries) {
                                                          headerObj$1[entries[0]] = entries[1];
                                                        });
                                                    var metaData$1 = Object.fromEntries([
                                                          [
                                                            "threeDSData",
                                                            threeDsData
                                                          ],
                                                          [
                                                            "paymentIntentId",
                                                            clientSecret
                                                          ],
                                                          [
                                                            "publishableKey",
                                                            confirmParam.publishableKey
                                                          ],
                                                          [
                                                            "headers",
                                                            headerObj$1
                                                          ],
                                                          [
                                                            "url",
                                                            url.href
                                                          ],
                                                          [
                                                            "iframeId",
                                                            iframeId
                                                          ]
                                                        ]);
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, do3dsMethodCall ? "Y" : "N", undefined, "THREE_DS_METHOD", paymentMethod, undefined);
                                                    if (do3dsMethodCall) {
                                                      return Utils$OrcaPaymentPage.messageParentWindow(undefined, [
                                                                  [
                                                                    "fullscreen",
                                                                    true
                                                                  ],
                                                                  [
                                                                    "param",
                                                                    "3ds"
                                                                  ],
                                                                  [
                                                                    "iframeId",
                                                                    iframeId
                                                                  ],
                                                                  [
                                                                    "metadata",
                                                                    metaData$1
                                                                  ]
                                                                ]);
                                                    } else {
                                                      metaData$1["3dsMethodComp"] = "U";
                                                      return Utils$OrcaPaymentPage.messageParentWindow(undefined, [
                                                                  [
                                                                    "fullscreen",
                                                                    true
                                                                  ],
                                                                  [
                                                                    "param",
                                                                    "3dsAuth"
                                                                  ],
                                                                  [
                                                                    "iframeId",
                                                                    iframeId
                                                                  ],
                                                                  [
                                                                    "metadata",
                                                                    metaData$1
                                                                  ]
                                                                ]);
                                                    }
                                                  }
                                                  if (intent.nextAction.type_ === "display_voucher_information") {
                                                    var voucherData = Core__Option.getOr(intent.nextAction.voucher_details, {
                                                          download_url: "",
                                                          reference: ""
                                                        });
                                                    var headerObj$2 = {};
                                                    headers.forEach(function (entries) {
                                                          headerObj$2[entries[0]] = entries[1];
                                                        });
                                                    var metaData$2 = Object.fromEntries([
                                                          [
                                                            "voucherUrl",
                                                            voucherData.download_url
                                                          ],
                                                          [
                                                            "reference",
                                                            voucherData.reference
                                                          ],
                                                          [
                                                            "returnUrl",
                                                            url.href
                                                          ],
                                                          [
                                                            "paymentMethod",
                                                            paymentMethod
                                                          ],
                                                          [
                                                            "payment_intent_data",
                                                            data
                                                          ]
                                                        ]);
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, "", JSON.stringify(metaData$2), "DISPLAY_VOUCHER", paymentMethod, undefined);
                                                    return Utils$OrcaPaymentPage.messageParentWindow(undefined, [
                                                                [
                                                                  "fullscreen",
                                                                  true
                                                                ],
                                                                [
                                                                  "param",
                                                                  "voucherData"
                                                                ],
                                                                [
                                                                  "iframeId",
                                                                  iframeId
                                                                ],
                                                                [
                                                                  "metadata",
                                                                  metaData$2
                                                                ]
                                                              ]);
                                                  }
                                                  if (intent.nextAction.type_ === "third_party_sdk_session_token") {
                                                    var token = intent.nextAction.session_token;
                                                    var session_token = token !== undefined ? Utils$OrcaPaymentPage.getDictFromJson(token) : ({});
                                                    var walletName = Utils$OrcaPaymentPage.getString(session_token, "wallet_name", "");
                                                    var message;
                                                    switch (walletName) {
                                                      case "apple_pay" :
                                                          message = [
                                                            [
                                                              "applePayButtonClicked",
                                                              true
                                                            ],
                                                            [
                                                              "applePayPresent",
                                                              session_token
                                                            ],
                                                            [
                                                              "componentName",
                                                              componentName
                                                            ]
                                                          ];
                                                          break;
                                                      case "google_pay" :
                                                          message = [[
                                                              "googlePayThirdPartyFlow",
                                                              session_token
                                                            ]];
                                                          break;
                                                      case "open_banking" :
                                                          var metaData$3 = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                                                [
                                                                  "linkToken",
                                                                  Utils$OrcaPaymentPage.getString(session_token, "open_banking_session_token", "")
                                                                ],
                                                                [
                                                                  "pmAuthConnectorArray",
                                                                  ["plaid"]
                                                                ],
                                                                [
                                                                  "publishableKey",
                                                                  confirmParam.publishableKey
                                                                ],
                                                                [
                                                                  "clientSecret",
                                                                  clientSecret
                                                                ],
                                                                [
                                                                  "isForceSync",
                                                                  true
                                                                ]
                                                              ]);
                                                          message = [
                                                            [
                                                              "fullscreen",
                                                              true
                                                            ],
                                                            [
                                                              "param",
                                                              "plaidSDK"
                                                            ],
                                                            [
                                                              "iframeId",
                                                              iframeId
                                                            ],
                                                            [
                                                              "metadata",
                                                              metaData$3
                                                            ]
                                                          ];
                                                          break;
                                                      default:
                                                        message = [];
                                                    }
                                                    if (!isPaymentSession) {
                                                      Utils$OrcaPaymentPage.messageParentWindow(undefined, message);
                                                    }
                                                    return resolve(data);
                                                  }
                                                  if (intent.nextAction.type_ === "invoke_sdk_client") {
                                                    var nextActionData = Core__Option.getOr(intent.nextAction.next_action_data, null);
                                                    var response = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                                          [
                                                            "orderId",
                                                            intent.connectorTransactionId
                                                          ],
                                                          [
                                                            "nextActionData",
                                                            nextActionData
                                                          ]
                                                        ]);
                                                    return resolve(response);
                                                  }
                                                  if (!isPaymentSession) {
                                                    Utils$OrcaPaymentPage.postFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
                                                  }
                                                  if (uri.includes("force_sync=true")) {
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, intent.nextAction.type_, intent.nextAction.type_, "REDIRECTING_USER", paymentMethod, "ERROR");
                                                    return handleOpenUrl(url.href);
                                                  }
                                                  var failedSubmitResponse = Utils$OrcaPaymentPage.getFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
                                                  return resolve(failedSubmitResponse);
                                                }
                                                if (intent.status === "requires_payment_method") {
                                                  if (intent.nextAction.type_ !== "invoke_sdk_client") {
                                                    return ;
                                                  }
                                                  var nextActionData$1 = Core__Option.getOr(intent.nextAction.next_action_data, null);
                                                  var response$1 = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                                        [
                                                          "orderId",
                                                          intent.connectorTransactionId
                                                        ],
                                                        [
                                                          "nextActionData",
                                                          nextActionData$1
                                                        ]
                                                      ]);
                                                  return resolve(response$1);
                                                }
                                                if (intent.status === "processing") {
                                                  if (intent.nextAction.type_ === "third_party_sdk_session_token") {
                                                    var token$1 = intent.nextAction.session_token;
                                                    var session_token$1 = token$1 !== undefined ? Utils$OrcaPaymentPage.getDictFromJson(token$1) : ({});
                                                    var walletName$1 = Utils$OrcaPaymentPage.getString(session_token$1, "wallet_name", "");
                                                    var message$1;
                                                    switch (walletName$1) {
                                                      case "apple_pay" :
                                                          message$1 = [
                                                            [
                                                              "applePayButtonClicked",
                                                              true
                                                            ],
                                                            [
                                                              "applePayPresent",
                                                              session_token$1
                                                            ]
                                                          ];
                                                          break;
                                                      case "google_pay" :
                                                          message$1 = [[
                                                              "googlePayThirdPartyFlow",
                                                              session_token$1
                                                            ]];
                                                          break;
                                                      default:
                                                        message$1 = [];
                                                    }
                                                    if (!isPaymentSession) {
                                                      Utils$OrcaPaymentPage.messageParentWindow(undefined, message$1);
                                                    }
                                                    
                                                  } else {
                                                    handleProcessingStatus(paymentType, sdkHandleOneClickConfirmPayment);
                                                  }
                                                  return resolve(data);
                                                }
                                                if (intent.status !== "") {
                                                  if (intent.status === "succeeded") {
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, intent.status, undefined, "PAYMENT_SUCCESS", paymentMethod, undefined);
                                                  } else if (intent.status === "failed") {
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, intent.status, undefined, "PAYMENT_FAILED", paymentMethod, undefined);
                                                  }
                                                  if (intent.status === "failed") {
                                                    setIsManualRetryEnabled(function (param) {
                                                          return intent.manualRetryAllowed;
                                                        });
                                                  }
                                                  return handleProcessingStatus(paymentType, sdkHandleOneClickConfirmPayment);
                                                }
                                                if (!isPaymentSession) {
                                                  return Utils$OrcaPaymentPage.postFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
                                                }
                                                var failedSubmitResponse$1 = Utils$OrcaPaymentPage.getFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
                                                resolve(failedSubmitResponse$1);
                                              })).then(function (prim) {
                                            return Promise.resolve(prim);
                                          });
                              });
                  }
                }), (function (err) {
                return new Promise((function (resolve, param) {
                                try {
                                  var url = new URL(confirmParam.return_url);
                                  url.searchParams.set("payment_intent_client_secret", clientSecret);
                                  url.searchParams.set("status", "failed");
                                  var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                                  LoggerUtils$OrcaPaymentPage.logApi(eventName, undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession);
                                  if (counter >= 5) {
                                    if (!isPaymentSession) {
                                      closePaymentLoaderIfAny();
                                      Utils$OrcaPaymentPage.postFailedSubmitResponse("server_error", "Something went wrong");
                                    }
                                    if (handleUserError) {
                                      return handleOpenUrl(url.href);
                                    }
                                    var failedSubmitResponse = Utils$OrcaPaymentPage.getFailedSubmitResponse("server_error", "Something went wrong");
                                    return resolve(failedSubmitResponse);
                                  }
                                  var paymentIntentID = Utils$OrcaPaymentPage.getPaymentId(clientSecret);
                                  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(confirmParam.publishableKey, isConfirm);
                                  var retrieveUri = endpoint + "/payments/" + paymentIntentID + "?client_secret=" + clientSecret;
                                  intentCall(fetchApi, retrieveUri, headers, bodyStr, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, "GET", setIsManualRetryEnabled, customPodUri, sdkHandleOneClickConfirmPayment, counter + 1 | 0, isPaymentSession, undefined, componentName, shouldUseTopRedirection).then(function (res) {
                                        resolve(res);
                                        return Promise.resolve();
                                      });
                                  return ;
                                }
                                catch (exn){
                                  if (!isPaymentSession) {
                                    Utils$OrcaPaymentPage.postFailedSubmitResponse("error", "Something went wrong");
                                  }
                                  var failedSubmitResponse$1 = Utils$OrcaPaymentPage.getFailedSubmitResponse("server_error", "Something went wrong");
                                  return resolve(failedSubmitResponse$1);
                                }
                              })).then(function (prim) {
                            return Promise.resolve(prim);
                          });
              }));
}

function usePaymentSync(optLogger, paymentType) {
  var paymentMethodList = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.paymentMethodList);
  var keys = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var isCallbackUsedVal = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.isCompleteCallbackUsed);
  var customPodUri = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.customPodUri);
  var shouldUseTopRedirection = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.shouldUseTopRedirectionAtom);
  var setIsManualRetryEnabled = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isManualRetryEnabled);
  return function (handleUserErrorOpt, confirmParam, iframeIdOpt) {
    var handleUserError = handleUserErrorOpt !== undefined ? handleUserErrorOpt : false;
    var iframeId = iframeIdOpt !== undefined ? iframeIdOpt : "";
    var clientSecret = keys.clientSecret;
    if (clientSecret === undefined) {
      return Utils$OrcaPaymentPage.postFailedSubmitResponse("sync_payment_failed", "Sync Payment Failed. Try Again!");
    }
    var paymentIntentID = Utils$OrcaPaymentPage.getPaymentId(clientSecret);
    var headers = [
      [
        "Content-Type",
        "application/json"
      ],
      [
        "api-key",
        confirmParam.publishableKey
      ]
    ];
    var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(confirmParam.publishableKey, undefined);
    var uri = endpoint + "/payments/" + paymentIntentID + "?force_sync=true&client_secret=" + clientSecret;
    var paymentSync = function () {
      intentCall(Utils$OrcaPaymentPage.fetchApi, uri, headers, "", confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, "GET", setIsManualRetryEnabled, customPodUri, keys.sdkHandleOneClickConfirmPayment, 0, undefined, isCallbackUsedVal, undefined, shouldUseTopRedirection);
    };
    if (typeof paymentMethodList !== "object" || paymentMethodList.TAG !== "Loaded") {
      return ;
    } else {
      return paymentSync();
    }
  };
}

function maskStr(str) {
  return str.replace(/\S/g, "x");
}

function maskPayload(payloadJson) {
  var valueDict = Core__JSON.Classify.classify(payloadJson);
  if (typeof valueDict !== "object") {
    return "null";
  }
  switch (valueDict.TAG) {
    case "Bool" :
        if (valueDict._0) {
          return "true";
        } else {
          return "false";
        }
    case "String" :
        return maskStr(valueDict._0);
    case "Number" :
        return maskStr(valueDict._0.toString());
    case "Object" :
        return Utils$OrcaPaymentPage.getJsonFromArrayOfJson(Object.entries(valueDict._0).map(function (entry) {
                        return [
                                entry[0],
                                maskPayload(entry[1])
                              ];
                      }));
    case "Array" :
        return valueDict._0.map(maskPayload);
    
  }
}

function usePaymentIntent(optLogger, paymentType) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var componentName = CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "componentName");
  var paymentTypeFromUrl = CardThemeType$OrcaPaymentPage.getPaymentMode(componentName);
  var blockConfirm = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.isConfirmBlocked);
  var customPodUri = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.customPodUri);
  var paymentMethodList = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.paymentMethodList);
  var keys = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var isCallbackUsedVal = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.isCompleteCallbackUsed);
  var shouldUseTopRedirection = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.shouldUseTopRedirectionAtom);
  var setIsManualRetryEnabled = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isManualRetryEnabled);
  return function (handleUserErrorOpt, bodyArr, confirmParam, iframeIdOpt, isThirdPartyFlowOpt, intentCallbackOpt, manualRetryOpt) {
    var handleUserError = handleUserErrorOpt !== undefined ? handleUserErrorOpt : false;
    var iframeId = iframeIdOpt !== undefined ? iframeIdOpt : keys.iframeId;
    var isThirdPartyFlow = isThirdPartyFlowOpt !== undefined ? isThirdPartyFlowOpt : false;
    var intentCallback = intentCallbackOpt !== undefined ? intentCallbackOpt : (function (param) {
          
        });
    var manualRetry = manualRetryOpt !== undefined ? manualRetryOpt : false;
    var clientSecret = keys.clientSecret;
    if (clientSecret === undefined) {
      return Utils$OrcaPaymentPage.postFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
    }
    var paymentIntentID = Utils$OrcaPaymentPage.getPaymentId(clientSecret);
    var headers = [
      [
        "Content-Type",
        "application/json"
      ],
      [
        "api-key",
        confirmParam.publishableKey
      ],
      [
        "X-Client-Source",
        CardThemeType$OrcaPaymentPage.getPaymentModeToStrMapper(paymentTypeFromUrl)
      ]
    ];
    var returnUrlArr = [[
        "return_url",
        confirmParam.return_url
      ]];
    var manual_retry = manualRetry ? [[
          "retry_action",
          "manual_retry"
        ]] : [];
    var body = [[
          "client_secret",
          clientSecret
        ]].concat(returnUrlArr, manual_retry);
    var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(confirmParam.publishableKey, isThirdPartyFlow);
    var uri = endpoint + "/payments/" + paymentIntentID + "/confirm";
    var callIntent = function (body) {
      var contentLength = body.length.toString();
      var maskedPayload = JSON.stringify(maskPayload(Core__Option.getOr(Utils$OrcaPaymentPage.safeParseOpt(body), null)));
      var loggerPayload = JSON.stringify(Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                [
                  "payload",
                  maskedPayload
                ],
                [
                  "headers",
                  Utils$OrcaPaymentPage.getJsonFromArrayOfJson(headers.map(function (header) {
                            return [
                                    header[0],
                                    header[1]
                                  ];
                          }))
                ]
              ]));
      if (paymentType === "Card") {
        LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, contentLength, loggerPayload, "PAYMENT_ATTEMPT", "CARD", undefined);
      } else {
        bodyArr.forEach(function (param) {
              if (param[0] === "payment_method_type") {
                LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, contentLength, loggerPayload, "PAYMENT_ATTEMPT", Utils$OrcaPaymentPage.getStringFromJson(param[1], ""), undefined);
              }
              
            });
      }
      if (blockConfirm && Window$OrcaPaymentPage.isInteg) {
        console.log("CONFIRM IS BLOCKED", Utils$OrcaPaymentPage.safeParse(body), headers);
      } else {
        intentCall(Utils$OrcaPaymentPage.fetchApi, uri, headers, body, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, "POST", setIsManualRetryEnabled, customPodUri, keys.sdkHandleOneClickConfirmPayment, 0, undefined, isCallbackUsedVal, componentName, shouldUseTopRedirection).then(function (val) {
              intentCallback(val);
              return Promise.resolve();
            });
      }
    };
    var intentWithoutMandate = function (mandatePaymentType) {
      var bodyStr = JSON.stringify(Utils$OrcaPaymentPage.getJsonFromArrayOfJson(body.concat(bodyArr.concat(BrowserSpec$OrcaPaymentPage.broswerInfo()), PaymentBody$OrcaPaymentPage.paymentTypeBody(mandatePaymentType))));
      callIntent(bodyStr);
    };
    if (typeof paymentMethodList !== "object") {
      if (paymentMethodList === "Loading") {
        return Utils$OrcaPaymentPage.postFailedSubmitResponse("payment_methods_loading", "Please wait. Try again!");
      } else {
        return intentWithoutMandate("");
      }
    }
    paymentMethodList.TAG === "Loaded";
    var paymentList = PaymentMethodsRecord$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(paymentMethodList._0));
    var mandatePaymentType = PaymentMethodsRecord$OrcaPaymentPage.paymentTypeToStringMapper(paymentList.payment_type);
    if (paymentList.payment_methods.length > 0) {
      var match = paymentList.mandate_payment;
      if (match === undefined) {
        return intentWithoutMandate(mandatePaymentType);
      }
      switch (paymentType) {
        case "BankTransfer" :
        case "Paze" :
        case "Other" :
            return intentWithoutMandate(mandatePaymentType);
        default:
          var bodyStr = JSON.stringify(Utils$OrcaPaymentPage.getJsonFromArrayOfJson(body.concat(bodyArr.concat(PaymentBody$OrcaPaymentPage.mandateBody(mandatePaymentType), BrowserSpec$OrcaPaymentPage.broswerInfo()))));
          return callIntent(bodyStr);
      }
    } else {
      Utils$OrcaPaymentPage.postFailedSubmitResponse("payment_methods_empty", "Payment Failed. Try again!");
      console.warn("Please enable atleast one Payment method.");
      return ;
    }
  };
}

function useCompleteAuthorize(optLogger, paymentType) {
  var paymentMethodList = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.paymentMethodList);
  var keys = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var customPodUri = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.customPodUri);
  var setIsManualRetryEnabled = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isManualRetryEnabled);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var isCallbackUsedVal = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.isCompleteCallbackUsed);
  var shouldUseTopRedirection = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.shouldUseTopRedirectionAtom);
  var paymentTypeFromUrl = CardThemeType$OrcaPaymentPage.getPaymentMode(CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "componentName"));
  return function (handleUserErrorOpt, bodyArr, confirmParam, iframeIdOpt) {
    var handleUserError = handleUserErrorOpt !== undefined ? handleUserErrorOpt : false;
    var iframeId = iframeIdOpt !== undefined ? iframeIdOpt : keys.iframeId;
    var clientSecret = keys.clientSecret;
    if (clientSecret === undefined) {
      return Utils$OrcaPaymentPage.postFailedSubmitResponse("complete_authorize_failed", "Complete Authorize Failed. Try Again!");
    }
    var paymentIntentID = Utils$OrcaPaymentPage.getPaymentId(clientSecret);
    var headers = [
      [
        "Content-Type",
        "application/json"
      ],
      [
        "api-key",
        confirmParam.publishableKey
      ],
      [
        "X-Client-Source",
        CardThemeType$OrcaPaymentPage.getPaymentModeToStrMapper(paymentTypeFromUrl)
      ]
    ];
    var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(confirmParam.publishableKey, undefined);
    var uri = endpoint + "/payments/" + paymentIntentID + "/complete_authorize";
    var bodyStr = JSON.stringify(Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                  "client_secret",
                  clientSecret
                ]].concat(bodyArr, BrowserSpec$OrcaPaymentPage.broswerInfo())));
    var completeAuthorize = function () {
      intentCall(Utils$OrcaPaymentPage.fetchApi, uri, headers, bodyStr, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, "POST", setIsManualRetryEnabled, customPodUri, keys.sdkHandleOneClickConfirmPayment, 0, undefined, isCallbackUsedVal, undefined, shouldUseTopRedirection);
    };
    if (typeof paymentMethodList !== "object" || paymentMethodList.TAG !== "Loaded") {
      return ;
    } else {
      return completeAuthorize();
    }
  };
}

function fetchSessions(clientSecret, publishableKey, walletsOpt, isDelayedSessionTokenOpt, optLogger, customPodUri, endpoint, isPaymentSessionOpt, merchantHostnameOpt) {
  var wallets = walletsOpt !== undefined ? walletsOpt : [];
  var isDelayedSessionToken = isDelayedSessionTokenOpt !== undefined ? isDelayedSessionTokenOpt : false;
  var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
  var merchantHostname = merchantHostnameOpt !== undefined ? merchantHostnameOpt : window.location.hostname;
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ],
    [
      "X-Merchant-Domain",
      merchantHostname
    ]
  ];
  var paymentIntentID = Utils$OrcaPaymentPage.getPaymentId(clientSecret);
  var body = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
        [
          "payment_id",
          paymentIntentID
        ],
        [
          "client_secret",
          clientSecret
        ],
        [
          "wallets",
          wallets
        ],
        [
          "delayed_session_token",
          isDelayedSessionToken
        ]
      ]);
  var uri = endpoint + "/payments/session_tokens";
  LoggerUtils$OrcaPaymentPage.logApi("SESSIONS_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, optLogger, "INFO", "API", isPaymentSession);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, JSON.stringify(body), ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, customPodUri), "POST").then(function (resp) {
                  var statusCode = resp.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return resp.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("SESSIONS_CALL", statusCode, data, "Err", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("SESSIONS_CALL", statusCode, undefined, "Response", uri, undefined, undefined, optLogger, "INFO", "API", isPaymentSession);
                    return resp.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                LoggerUtils$OrcaPaymentPage.logApi("SESSIONS_CALL", undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession);
                return Promise.resolve(null);
              }));
}

function confirmPayout(clientSecret, publishableKey, logger, customPodUri, uri, body) {
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ]
  ];
  LoggerUtils$OrcaPaymentPage.logApi("CONFIRM_PAYOUT_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, logger, "INFO", "API", undefined);
  var body$1 = Utils$OrcaPaymentPage.getJsonFromArrayOfJson(body.concat([[
              "client_secret",
              clientSecret
            ]]));
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, JSON.stringify(body$1), ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, customPodUri), "POST").then(function (resp) {
                  var statusCode = resp.status.toString();
                  return resp.json().then(function (data) {
                              if (statusCode.charAt(0) !== "2") {
                                LoggerUtils$OrcaPaymentPage.logApi("CONFIRM_PAYOUT_CALL", statusCode, data, "Err", uri, undefined, undefined, logger, "ERROR", "API", undefined);
                              } else {
                                LoggerUtils$OrcaPaymentPage.logApi("CONFIRM_PAYOUT_CALL", statusCode, undefined, "Response", uri, undefined, undefined, logger, "INFO", "API", undefined);
                              }
                              return Promise.resolve(data);
                            });
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                LoggerUtils$OrcaPaymentPage.logApi("CONFIRM_PAYOUT_CALL", undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, logger, "ERROR", "API", undefined);
                return Promise.resolve(null);
              }));
}

function createPaymentMethod(clientSecret, publishableKey, logger, customPodUri, endpoint, body) {
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ]
  ];
  var uri = endpoint + "/payment_methods";
  LoggerUtils$OrcaPaymentPage.logApi("CREATE_CUSTOMER_PAYMENT_METHODS_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, logger, "INFO", "API", undefined);
  var body$1 = Utils$OrcaPaymentPage.getJsonFromArrayOfJson(body.concat([[
              "client_secret",
              clientSecret
            ]]));
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, JSON.stringify(body$1), ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, customPodUri), "POST").then(function (resp) {
                  var statusCode = resp.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return resp.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("CREATE_CUSTOMER_PAYMENT_METHODS_CALL", statusCode, data, "Err", uri, undefined, undefined, logger, "ERROR", "API", undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("CREATE_CUSTOMER_PAYMENT_METHODS_CALL", statusCode, undefined, "Response", uri, undefined, undefined, logger, "INFO", "API", undefined);
                    return resp.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                LoggerUtils$OrcaPaymentPage.logApi("CREATE_CUSTOMER_PAYMENT_METHODS_CALL", undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, logger, "ERROR", "API", undefined);
                return Promise.resolve(null);
              }));
}

function fetchPaymentMethodList(clientSecret, publishableKey, logger, customPodUri, endpoint) {
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ]
  ];
  var uri = endpoint + "/account/payment_methods?client_secret=" + clientSecret;
  LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, logger, "INFO", "API", undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, undefined, ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, customPodUri), "GET").then(function (resp) {
                  var statusCode = resp.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return resp.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_CALL", statusCode, data, "Err", uri, undefined, undefined, logger, "ERROR", "API", undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_CALL", statusCode, undefined, "Response", uri, undefined, undefined, logger, "INFO", "API", undefined);
                    return resp.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_CALL", undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, logger, "ERROR", "API", undefined);
                return Promise.resolve(null);
              }));
}

function fetchCustomerPaymentMethodList(clientSecret, publishableKey, endpoint, optLogger, customPodUri, isPaymentSessionOpt) {
  var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ]
  ];
  var uri = endpoint + "/customers/payment_methods?client_secret=" + clientSecret;
  LoggerUtils$OrcaPaymentPage.logApi("CUSTOMER_PAYMENT_METHODS_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, optLogger, "INFO", "API", isPaymentSession);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, undefined, ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, customPodUri), "GET").then(function (res) {
                  var statusCode = res.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return res.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("CUSTOMER_PAYMENT_METHODS_CALL", statusCode, data, "Err", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("CUSTOMER_PAYMENT_METHODS_CALL", statusCode, undefined, "Response", uri, undefined, undefined, optLogger, "INFO", "API", isPaymentSession);
                    return res.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                LoggerUtils$OrcaPaymentPage.logApi("CUSTOMER_PAYMENT_METHODS_CALL", undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession);
                return Promise.resolve(null);
              }));
}

function paymentIntentForPaymentSession(body, paymentType, payload, publishableKey, clientSecret, logger, customPodUri, shouldUseTopRedirection) {
  var confirmParams = Utils$OrcaPaymentPage.getDictFromDict(Utils$OrcaPaymentPage.getDictFromJson(payload), "confirmParams");
  var redirect = Utils$OrcaPaymentPage.getString(confirmParams, "redirect", "if_required");
  var returnUrl = Utils$OrcaPaymentPage.getString(confirmParams, "return_url", "");
  var confirmParam_redirect = redirect;
  var confirmParam = {
    return_url: returnUrl,
    publishableKey: publishableKey,
    redirect: confirmParam_redirect
  };
  var paymentIntentID = Core__Option.getOr(clientSecret.split("_secret_")[0], "");
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(publishableKey, true);
  var uri = endpoint + "/payments/" + paymentIntentID + "/confirm";
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ]
  ];
  var broswerInfo = BrowserSpec$OrcaPaymentPage.broswerInfo();
  var returnUrlArr = [[
      "return_url",
      returnUrl
    ]];
  var bodyStr = JSON.stringify(Utils$OrcaPaymentPage.getJsonFromArrayOfJson(body.concat(broswerInfo, [[
                  "client_secret",
                  clientSecret
                ]], returnUrlArr)));
  return intentCall(Utils$OrcaPaymentPage.fetchApi, uri, headers, bodyStr, confirmParam, clientSecret, logger, false, paymentType, "", "POST", (function (param) {
                
              }), customPodUri, false, 0, true, undefined, undefined, shouldUseTopRedirection);
}

function callAuthLink(publishableKey, clientSecret, paymentMethodType, pmAuthConnectorsArr, iframeId, optLogger) {
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(undefined, undefined);
  var uri = endpoint + "/payment_methods/auth/link";
  var headers = Object.fromEntries([
        [
          "Content-Type",
          "application/json"
        ],
        [
          "api-key",
          publishableKey
        ]
      ]);
  LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_AUTH_LINK_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, optLogger, "INFO", "API", undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, JSON.stringify(Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                            [
                              "client_secret",
                              Core__Option.getOr(clientSecret, "")
                            ],
                            [
                              "payment_id",
                              Utils$OrcaPaymentPage.getPaymentId(Core__Option.getOr(clientSecret, ""))
                            ],
                            [
                              "payment_method",
                              "bank_debit"
                            ],
                            [
                              "payment_method_type",
                              paymentMethodType
                            ]
                          ])), headers, "POST").then(function (res) {
                  var statusCode = res.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return res.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_AUTH_LINK_CALL", statusCode, data, "Err", uri, undefined, undefined, optLogger, "ERROR", "API", undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    return res.json().then(function (data) {
                                var metaData = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                      [
                                        "linkToken",
                                        Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(data), "link_token", "")
                                      ],
                                      [
                                        "pmAuthConnectorArray",
                                        pmAuthConnectorsArr
                                      ],
                                      [
                                        "publishableKey",
                                        publishableKey
                                      ],
                                      [
                                        "clientSecret",
                                        Core__Option.getOr(clientSecret, "")
                                      ],
                                      [
                                        "isForceSync",
                                        false
                                      ]
                                    ]);
                                Utils$OrcaPaymentPage.messageParentWindow(undefined, [
                                      [
                                        "fullscreen",
                                        true
                                      ],
                                      [
                                        "param",
                                        "plaidSDK"
                                      ],
                                      [
                                        "iframeId",
                                        iframeId
                                      ],
                                      [
                                        "metadata",
                                        metaData
                                      ]
                                    ]);
                                LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_AUTH_LINK_CALL", statusCode, undefined, "Response", uri, undefined, undefined, optLogger, "INFO", "API", undefined);
                                return Promise.resolve(null);
                              });
                  }
                }), (function (e) {
                LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_AUTH_LINK_CALL", undefined, Utils$OrcaPaymentPage.formatException(e), "NoResponse", uri, undefined, undefined, optLogger, "ERROR", "API", undefined);
                console.log("Unable to retrieve payment_methods auth/link because of ", e);
                return Promise.resolve(null);
              }));
}

function callAuthExchange(publicToken, clientSecret, paymentMethodType, publishableKey, setOptionValue, optLogger) {
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(undefined, undefined);
  var logger = HyperLogger$OrcaPaymentPage.make(undefined, {
        TAG: "Elements",
        _0: "Payment"
      }, undefined, undefined, undefined, undefined);
  var uri = endpoint + "/payment_methods/auth/exchange";
  var updatedBody = [
    [
      "client_secret",
      Core__Option.getOr(clientSecret, "")
    ],
    [
      "payment_id",
      Utils$OrcaPaymentPage.getPaymentId(Core__Option.getOr(clientSecret, ""))
    ],
    [
      "payment_method",
      "bank_debit"
    ],
    [
      "payment_method_type",
      paymentMethodType
    ],
    [
      "public_token",
      publicToken
    ]
  ];
  var headers = Object.fromEntries([
        [
          "Content-Type",
          "application/json"
        ],
        [
          "api-key",
          publishableKey
        ]
      ]);
  LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_AUTH_EXCHANGE_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, optLogger, "INFO", "API", undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, JSON.stringify(Utils$OrcaPaymentPage.getJsonFromArrayOfJson(updatedBody)), headers, "POST").then(function (res) {
                  var statusCode = res.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return res.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_AUTH_EXCHANGE_CALL", statusCode, data, "Err", uri, undefined, undefined, optLogger, "ERROR", "API", undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_AUTH_EXCHANGE_CALL", statusCode, undefined, "Response", uri, undefined, undefined, optLogger, "INFO", "API", undefined);
                    return Core__Promise.$$catch(fetchCustomerPaymentMethodList(Core__Option.getOr(clientSecret, ""), publishableKey, endpoint, logger, "", undefined).then(function (customerListResponse) {
                                    var customerListResponse$1 = Object.fromEntries([[
                                            "customerPaymentMethods",
                                            customerListResponse
                                          ]]);
                                    setOptionValue(function (prev) {
                                          var newrecord = Caml_obj.obj_dup(prev);
                                          newrecord.customerPaymentMethods = PaymentType$OrcaPaymentPage.createCustomerObjArr(customerListResponse$1, "customerPaymentMethods");
                                          return newrecord;
                                        });
                                    return Promise.resolve(null);
                                  }), (function (e) {
                                  console.log("Unable to retrieve customer/payment_methods after auth/exchange because of ", e);
                                  return Promise.resolve(null);
                                }));
                  }
                }), (function (e) {
                LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_AUTH_EXCHANGE_CALL", undefined, Utils$OrcaPaymentPage.formatException(e), "NoResponse", uri, undefined, undefined, optLogger, "ERROR", "API", undefined);
                console.log("Unable to retrieve payment_methods auth/exchange because of ", e);
                return Promise.resolve(null);
              }));
}

function fetchSavedPaymentMethodList(ephemeralKey, endpoint, optLogger, customPodUri, isPaymentSessionOpt) {
  var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      ephemeralKey
    ]
  ];
  var uri = endpoint + "/customers/payment_methods";
  LoggerUtils$OrcaPaymentPage.logApi("SAVED_PAYMENT_METHODS_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, optLogger, "INFO", "API", isPaymentSession);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, undefined, ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, customPodUri), "GET").then(function (res) {
                  var statusCode = res.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return res.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("CUSTOMER_PAYMENT_METHODS_CALL", statusCode, data, "Err", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("CUSTOMER_PAYMENT_METHODS_CALL", statusCode, undefined, "Response", uri, undefined, undefined, optLogger, "INFO", "API", isPaymentSession);
                    return res.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                LoggerUtils$OrcaPaymentPage.logApi("CUSTOMER_PAYMENT_METHODS_CALL", undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession);
                return Promise.resolve(null);
              }));
}

function deletePaymentMethod(ephemeralKey, paymentMethodId, logger, customPodUri) {
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(undefined, undefined);
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      ephemeralKey
    ]
  ];
  var uri = endpoint + "/payment_methods/" + paymentMethodId;
  LoggerUtils$OrcaPaymentPage.logApi("DELETE_PAYMENT_METHODS_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, logger, "INFO", "API", undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, undefined, ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, customPodUri), "DELETE").then(function (resp) {
                  var statusCode = resp.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return resp.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("DELETE_PAYMENT_METHODS_CALL", statusCode, data, "Err", uri, undefined, undefined, logger, "ERROR", "API", undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("DELETE_PAYMENT_METHODS_CALL", statusCode, undefined, "Response", uri, undefined, undefined, logger, "INFO", "API", undefined);
                    return resp.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                LoggerUtils$OrcaPaymentPage.logApi("DELETE_PAYMENT_METHODS_CALL", undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, logger, "ERROR", "API", undefined);
                return Promise.resolve(null);
              }));
}

function calculateTax(apiKey, paymentId, clientSecret, paymentMethodType, shippingAddress, logger, customPodUri, sessionId) {
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(undefined, undefined);
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      apiKey
    ]
  ];
  var uri = endpoint + "/payments/" + paymentId + "/calculate_tax";
  var body = [
    [
      "client_secret",
      clientSecret
    ],
    [
      "shipping",
      shippingAddress
    ],
    [
      "payment_method_type",
      paymentMethodType
    ]
  ];
  Core__Option.mapOr(sessionId, undefined, (function (id) {
          body.push([
                "session_id",
                id
              ]);
        }));
  LoggerUtils$OrcaPaymentPage.logApi("EXTERNAL_TAX_CALCULATION", undefined, undefined, "Request", uri, undefined, undefined, logger, "INFO", "API", undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, JSON.stringify(Utils$OrcaPaymentPage.getJsonFromArrayOfJson(body)), ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, customPodUri), "POST").then(function (resp) {
                  var statusCode = resp.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return resp.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("EXTERNAL_TAX_CALCULATION", statusCode, data, "Err", uri, undefined, undefined, logger, "ERROR", "API", undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("EXTERNAL_TAX_CALCULATION", statusCode, undefined, "Response", uri, undefined, undefined, logger, "INFO", "API", undefined);
                    return resp.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                LoggerUtils$OrcaPaymentPage.logApi("EXTERNAL_TAX_CALCULATION", undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, logger, "ERROR", "API", undefined);
                return Promise.resolve(null);
              }));
}

function usePostSessionTokens(optLogger, paymentType, paymentMethod) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var paymentTypeFromUrl = CardThemeType$OrcaPaymentPage.getPaymentMode(CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "componentName"));
  var customPodUri = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.customPodUri);
  var paymentMethodList = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.paymentMethodList);
  var keys = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var shouldUseTopRedirection = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.shouldUseTopRedirectionAtom);
  var setIsManualRetryEnabled = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isManualRetryEnabled);
  return function (handleUserErrorOpt, bodyArr, confirmParam, iframeIdOpt, isThirdPartyFlowOpt, intentCallbackOpt, $staropt$star) {
    var handleUserError = handleUserErrorOpt !== undefined ? handleUserErrorOpt : false;
    var iframeId = iframeIdOpt !== undefined ? iframeIdOpt : keys.iframeId;
    var isThirdPartyFlow = isThirdPartyFlowOpt !== undefined ? isThirdPartyFlowOpt : false;
    var intentCallback = intentCallbackOpt !== undefined ? intentCallbackOpt : (function (param) {
          
        });
    if ($staropt$star === undefined) {
      false;
    }
    var clientSecret = keys.clientSecret;
    if (clientSecret === undefined) {
      return Utils$OrcaPaymentPage.postFailedSubmitResponse("post_session_tokens_failed", "Post Session Tokens failed. Try again!");
    }
    var paymentIntentID = Utils$OrcaPaymentPage.getPaymentId(clientSecret);
    var headers = [
      [
        "Content-Type",
        "application/json"
      ],
      [
        "api-key",
        confirmParam.publishableKey
      ],
      [
        "X-Client-Source",
        CardThemeType$OrcaPaymentPage.getPaymentModeToStrMapper(paymentTypeFromUrl)
      ]
    ];
    var body = [
      [
        "client_secret",
        clientSecret
      ],
      [
        "payment_id",
        paymentIntentID
      ],
      [
        "payment_method_type",
        paymentType
      ],
      [
        "payment_method",
        paymentMethod
      ]
    ];
    var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(confirmParam.publishableKey, isThirdPartyFlow);
    var uri = endpoint + "/payments/" + paymentIntentID + "/post_session_tokens";
    var callIntent = function (body) {
      var contentLength = body.length.toString();
      var maskedPayload = JSON.stringify(maskPayload(Core__Option.getOr(Utils$OrcaPaymentPage.safeParseOpt(body), null)));
      var loggerPayload = JSON.stringify(Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                [
                  "payload",
                  maskedPayload
                ],
                [
                  "headers",
                  Utils$OrcaPaymentPage.getJsonFromArrayOfJson(headers.map(function (header) {
                            return [
                                    header[0],
                                    header[1]
                                  ];
                          }))
                ]
              ]));
      if (paymentType === "Card") {
        LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, contentLength, loggerPayload, "PAYMENT_ATTEMPT", "CARD", undefined);
      } else {
        bodyArr.forEach(function (param) {
              if (param[0] === "payment_method_type") {
                LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, contentLength, loggerPayload, "PAYMENT_ATTEMPT", Utils$OrcaPaymentPage.getStringFromJson(param[1], ""), undefined);
              }
              
            });
      }
      intentCall(Utils$OrcaPaymentPage.fetchApi, uri, headers, body, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, "POST", setIsManualRetryEnabled, customPodUri, keys.sdkHandleOneClickConfirmPayment, 0, undefined, undefined, undefined, shouldUseTopRedirection).then(function (val) {
            intentCallback(val);
            return Promise.resolve();
          });
    };
    var intentWithoutMandate = function (mandatePaymentType) {
      var bodyStr = JSON.stringify(Utils$OrcaPaymentPage.getJsonFromArrayOfJson(body.concat(bodyArr.concat(BrowserSpec$OrcaPaymentPage.broswerInfo()), PaymentBody$OrcaPaymentPage.paymentTypeBody(mandatePaymentType))));
      callIntent(bodyStr);
    };
    if (typeof paymentMethodList !== "object") {
      if (paymentMethodList === "Loading") {
        return Utils$OrcaPaymentPage.postFailedSubmitResponse("payment_methods_loading", "Please wait. Try again!");
      } else {
        return intentWithoutMandate("");
      }
    }
    paymentMethodList.TAG === "Loaded";
    var paymentList = PaymentMethodsRecord$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(paymentMethodList._0));
    var mandatePaymentType = PaymentMethodsRecord$OrcaPaymentPage.paymentTypeToStringMapper(paymentList.payment_type);
    if (paymentList.payment_methods.length > 0) {
      var match = paymentList.mandate_payment;
      if (match === undefined) {
        return intentWithoutMandate(mandatePaymentType);
      }
      switch (paymentType) {
        case "BankTransfer" :
        case "Paze" :
        case "Other" :
            return intentWithoutMandate(mandatePaymentType);
        default:
          var bodyStr = JSON.stringify(Utils$OrcaPaymentPage.getJsonFromArrayOfJson(body.concat(bodyArr.concat(PaymentBody$OrcaPaymentPage.mandateBody(mandatePaymentType), BrowserSpec$OrcaPaymentPage.broswerInfo()))));
          return callIntent(bodyStr);
      }
    } else {
      Utils$OrcaPaymentPage.postFailedSubmitResponse("payment_methods_empty", "Payment Failed. Try again!");
      console.warn("Please enable atleast one Payment method.");
      return ;
    }
  };
}

export {
  getPaymentType ,
  closePaymentLoaderIfAny ,
  retrievePaymentIntent ,
  threeDsAuth ,
  pollRetrievePaymentIntent ,
  retrieveStatus ,
  pollStatus ,
  intentCall ,
  usePaymentSync ,
  maskStr ,
  maskPayload ,
  usePaymentIntent ,
  useCompleteAuthorize ,
  fetchSessions ,
  confirmPayout ,
  createPaymentMethod ,
  fetchPaymentMethodList ,
  fetchCustomerPaymentMethodList ,
  paymentIntentForPaymentSession ,
  callAuthLink ,
  callAuthExchange ,
  fetchSavedPaymentMethodList ,
  deletePaymentMethod ,
  calculateTax ,
  usePostSessionTokens ,
}
/* recoil Not a pure module */
