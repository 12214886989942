// Generated by ReScript, PLEASE EDIT WITH CARE


function getPaymentMode(val) {
  switch (val) {
    case "applePay" :
        return "ApplePayElement";
    case "card" :
        return "Card";
    case "cardCvc" :
        return "CardCVCElement";
    case "cardExpiry" :
        return "CardExpiryElement";
    case "cardNumber" :
        return "CardNumberElement";
    case "expressCheckout" :
        return "ExpressCheckoutElement";
    case "googlePay" :
        return "GooglePayElement";
    case "klarna" :
        return "KlarnaElement";
    case "payPal" :
        return "PayPalElement";
    case "payment" :
        return "Payment";
    case "paymentMethodCollect" :
        return "PaymentMethodCollectElement";
    case "paymentMethodsManagement" :
        return "PaymentMethodsManagement";
    case "paze" :
        return "PazeElement";
    default:
      return "NONE";
  }
}

function getPaymentModeToStrMapper(val) {
  return val;
}

export {
  getPaymentMode ,
  getPaymentModeToStrMapper ,
}
/* No side effect */
