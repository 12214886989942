// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

var defaultACHCreditTransfer = {
  account_number: "",
  bank_name: "",
  routing_number: "",
  swift_code: ""
};

var defaultBacsBankInstruction = {
  sort_code: "",
  account_number: "",
  account_holder_name: ""
};

var defaultNextAction = {
  redirectToUrl: "",
  type_: "",
  bank_transfer_steps_and_charges_details: undefined,
  session_token: undefined,
  image_data_url: undefined,
  three_ds_data: undefined,
  voucher_details: undefined,
  display_to_timestamp: undefined,
  next_action_data: undefined,
  display_text: undefined,
  border_color: undefined,
  iframe_data: undefined
};

var defaultIntent = {
  nextAction: defaultNextAction,
  status: "",
  paymentId: "",
  clientSecret: "",
  error_message: "",
  payment_method_type: "",
  manualRetryAllowed: false,
  connectorTransactionId: ""
};

function getAchCreditTransfer(dict, str) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    return {
                            account_number: Utils$OrcaPaymentPage.getString(json, "account_number", ""),
                            bank_name: Utils$OrcaPaymentPage.getString(json, "bank_name", ""),
                            routing_number: Utils$OrcaPaymentPage.getString(json, "routing_number", ""),
                            swift_code: Utils$OrcaPaymentPage.getString(json, "swift_code", "")
                          };
                  })), defaultACHCreditTransfer);
}

function getBacsBankInstructions(dict, str) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    return {
                            sort_code: Utils$OrcaPaymentPage.getString(json, "sort_code", ""),
                            account_number: Utils$OrcaPaymentPage.getString(json, "account_number", ""),
                            account_holder_name: Utils$OrcaPaymentPage.getString(json, "account_holder_name", "")
                          };
                  })), defaultBacsBankInstruction);
}

function getBankTransferDetails(dict, str) {
  return Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                return {
                        ach_credit_transfer: getAchCreditTransfer(json, "ach_credit_transfer")
                      };
              }));
}

function getVoucherDetails(json) {
  return {
          download_url: Utils$OrcaPaymentPage.getString(json, "download_url", ""),
          reference: Utils$OrcaPaymentPage.getString(json, "reference", "")
        };
}

function getNextAction(dict, str) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    return {
                            redirectToUrl: Utils$OrcaPaymentPage.getString(json, "redirect_to_url", ""),
                            type_: Utils$OrcaPaymentPage.getString(json, "type", ""),
                            bank_transfer_steps_and_charges_details: Utils$OrcaPaymentPage.getJsonObjFromDict(json, "bank_transfer_steps_and_charges_details", {}),
                            session_token: Utils$OrcaPaymentPage.getJsonObjFromDict(json, "session_token", {}),
                            image_data_url: Utils$OrcaPaymentPage.getString(json, "image_data_url", ""),
                            three_ds_data: Core__Option.getOr(json["three_ds_data"], {}),
                            voucher_details: Core__Option.map(Core__Option.flatMap(json["voucher_details"], Core__JSON.Decode.object), (function (json) {
                                    return getVoucherDetails(json);
                                  })),
                            display_to_timestamp: Core__Option.getOr(Core__Option.flatMap(json["display_to_timestamp"], Core__JSON.Decode.$$float), 0.0),
                            next_action_data: Utils$OrcaPaymentPage.getDictFromDict(json, "next_action_data"),
                            display_text: Utils$OrcaPaymentPage.getOptionString(json, "display_text"),
                            border_color: Utils$OrcaPaymentPage.getOptionString(json, "border_color"),
                            iframe_data: Utils$OrcaPaymentPage.getJsonObjectFromDict(json, "iframe_data")
                          };
                  })), defaultNextAction);
}

function itemToObjMapper(dict) {
  return {
          nextAction: getNextAction(dict, "next_action"),
          status: Utils$OrcaPaymentPage.getString(dict, "status", ""),
          paymentId: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
          clientSecret: Utils$OrcaPaymentPage.getString(dict, "client_secret", ""),
          error_message: Utils$OrcaPaymentPage.getString(dict, "error_message", ""),
          payment_method_type: Utils$OrcaPaymentPage.getString(dict, "payment_method_type", ""),
          manualRetryAllowed: Utils$OrcaPaymentPage.getBool(dict, "manual_retry_allowed", false),
          connectorTransactionId: Utils$OrcaPaymentPage.getString(dict, "connector_transaction_id", "")
        };
}

var defaultRedirectTourl = {
  returnUrl: "",
  url: ""
};

export {
  defaultACHCreditTransfer ,
  defaultBacsBankInstruction ,
  defaultRedirectTourl ,
  defaultNextAction ,
  defaultIntent ,
  getAchCreditTransfer ,
  getBacsBankInstructions ,
  getBankTransferDetails ,
  getVoucherDetails ,
  getNextAction ,
  itemToObjMapper ,
}
/* Utils-OrcaPaymentPage Not a pure module */
